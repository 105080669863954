import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import FilterSection from "../FilterSection";
import Chart from "../../../components/Charts/Chart";
import { useSelector, useDispatch } from "react-redux";
import { getMultipleModelsData } from "../action";
import _ from "lodash";
import { updateLineChartData } from "../data";
const HorizontalCharts = (props) => {
  
  const { charts, apiKeys } = props;
  const dispatch = useDispatch();
  const { tableInfo, formEditedValues } = useSelector((state) => state.home);
  const gridLength = 12 / charts.length;

  useEffect(() => {
    if (apiKeys) {
      getMultipleModelsData({
        model_names: apiKeys.map((obj) => obj.key),
      }).then((res) => {
        let params = {};
        let formEditing = {};
        apiKeys.forEach(async(apiObj, index) => {
          let chartData = [];
          let chartInfo = (res[apiObj.key] ||  [] );
          let groupedData = {};
          if (apiObj.action) {
            switch (apiObj.action.type) {
              case "ATTRIBUTE_GRADES":
                chartInfo = [...res[apiObj.key]];
                const clusterNames = Array.from(
                  {
                    length: apiObj.action.default_clusters || 2,
                  },
                  (_, i) => String.fromCharCode(64 + (i + 1))
                ).concat("Average");
                if (apiObj.action.default) {
                  chartInfo = chartInfo.filter(
                    (obj) =>
                      apiObj.action.default.indexOf(obj.attribute_type) !== -1
                  );
                }
                groupedData = _.groupBy(chartInfo, "name");
                if (Object.keys(groupedData).length > 0) {
                  Object.keys(groupedData).forEach((groupObj) => {
                    let params = { ...groupedData[`${groupObj}`][0] };
                    params["data"] = groupedData[`${groupObj}`]
                      .filter(
                        (filterObj) =>
                          clusterNames.indexOf(filterObj.labels) !== -1
                      )
                      .map((obj) => parseInt(obj.value));
                    console.log("Params list ", params);
                    chartData.push(params);
                  });
                }
                formEditing[`${apiObj.key}_grades`] = apiObj.action.default;
                break;
              case "PERFORMANCE_CLUSTERS":
                chartInfo = [...res[apiObj.key]];
                if (apiObj.action.default) {
                  chartInfo = chartInfo.filter(
                    (obj) =>
                      apiObj.action.default.indexOf(obj.name) !== -1 &&
                      obj.yAxis === "Txn Count" &&
                      obj.xAxis === "Sales Revenue"
                  );
                }
                groupedData = _.groupBy(chartInfo, "name");
                if (Object.keys(groupedData).length > 0) {
                  Object.keys(groupedData).forEach((obj) => {
                    let params = {
                      name: groupedData[`${obj}`][0].name,
                      color: groupedData[`${obj}`][0].color,
                    };
                    let clusterValues = [];
                    groupedData[`${obj}`].forEach((groupObj) => {
                      clusterValues.push([
                        parseFloat(groupObj.xAxisValue),
                        parseFloat(groupObj.yAxisValue),
                      ]);
                    });
                    params["data"] = clusterValues;
                    chartData.push(params);
                  });
                }
                formEditing[`${apiObj.key}_xAxis`] = ["Sales Revenue"];
                formEditing[`${apiObj.key}_yAxis`] = ["Txn Count"];

                break;
              case "DATA_CONVERTED_TO_GRAPH":
                chartInfo.filter(graphObj=>{
                  let xAxisData = []
                  console.log("Graph Obh",graphObj,apiObj.action.xAxisCategories)
                  apiObj.action.xAxisCategories.filter(xAxisObj=>{
                    xAxisData.push(parseInt(graphObj[`${xAxisObj}`]))
                  })
                  graphObj['data'] = xAxisData;
                  return graphObj;
                })
                params["data"] = chartInfo;
                chartData = chartInfo;
                break;
                
              case "DATA_CONVERTED_TO_BAR_CHART":
                let xAxisCategories = []
                if (apiObj.action.default) {
                  chartInfo = chartInfo.filter(
                    (obj) =>
                      apiObj.action.default.indexOf(obj.attribute_type) !== -1
                  );
                  if(apiObj.mappingKey){
                    const optionsList = []
                    apiObj.action.default.forEach(obj=>{
                      optionsList.push({
                        label: obj,
                        value: obj
                      })
                    })
                  formEditing[`${apiObj.mappingKey}`] = optionsList
                  }
                }
                if (apiObj.action.defaultYAxis) {
                  chartInfo = chartInfo.filter(
                    (obj) =>
                      apiObj.action.defaultYAxis.indexOf(obj.metric_name) !== -1
                  );
                  if(apiObj.mappingKey){
                    const optionsList = []
                    apiObj.action.defaultYAxis.forEach(obj=>{
                      optionsList.push({
                        label: obj,
                        value: obj
                      })
                    })
                    formEditing[`${apiObj.otherMappingKey}`] = optionsList
                  }
                }
                if (apiObj.action.defaultMetric) {
                  if(apiObj.otherMetricKey){
                    const optionsList = []
                    apiObj.action.defaultMetric.forEach(obj=>{
                      optionsList.push({
                        label: obj,
                        value: obj
                      })
                    })
                    formEditing[`${apiObj.otherMetricKey}`] = optionsList
                  }
                }
                charts[index].xaxis = {
                  "categories": _.uniq(chartInfo.map(obj=> obj.xAxis))
                }
                charts[index].yaxis = {
                  "categories": _.uniq(chartInfo.map(obj=> obj.yAxis)),
                  "tickInterval":apiObj.action.tickInterval
                }
                groupedData = _.groupBy(chartInfo, "name");
                console.log("Grouped Data ",groupedData)
                // debugger
                if (Object.keys(groupedData).length > 0) {
                  Object.keys(groupedData).forEach((groupObj) => {
                    let chartObj = { ...groupedData[`${groupObj}`][0] };
                    console.log("Group obj",chartObj)
                    chartObj["data"] = groupedData[`${groupObj}`]
                      .map(
                        (filterObj) =>
                          parseInt(filterObj.value)
                      )
                    console.log("Params list ", chartObj);
                    chartData.push({
                      "name": chartObj.name,
                      "color": chartObj.color,
                      "data":chartObj.data
                    });
                  });
                }
                params["data"] = chartData;
                chartData = chartData;
                break;
              case "DATA_CONVERTED_TO_LINE":

                  charts[index].xaxis = {
                    "categories": _.uniq(chartInfo.map((obj) => obj.week))
                  }
                  let convertedData =  updateLineChartData(chartInfo,apiObj.action.xAxisLabels)
                  
                  params["data"] = convertedData;
                  chartData = convertedData ;
                break;
              case "DATA_FROM_TABLE":
                chartData = [...chartInfo]
                break;
              default:
                break;
            }
          }

          params[`${apiObj.key}`] = chartData;
          params[`${apiObj.key}_copy`] = [...res[apiObj.key]];
          formEditing[`${apiObj.key}_cluster`] = apiObj.action.clusters;
          if (apiObj.isChartDynamic) {
            formEditing[`${apiObj.key}_chart`] = {
              yaxis: charts[index].yaxis,
              xaxis: charts[index].xaxis,
            };
          }
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });
        });
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });
      });
    }
  }, [apiKeys]);

  useEffect(() => {
    if (Object.keys(formEditedValues).length >0 && formEditedValues.isGraphDataUupdated) {
        let params = {};
        let formEditing = {};
        formEditedValues.graphApiKeys.forEach(async(apiObj, index) => {
          let chartData = [];
          let chartInfo = (tableInfo[apiObj.key] ||  [] );
          let groupedData = {};
          if (apiObj.action) {
            switch (apiObj.action.type) {
              case "DATA_CONVERTED_TO_GRAPH":
                chartInfo.filter(graphObj=>{
                  let xAxisData = []
                  apiObj.action.xAxisCategories.filter(xAxisObj=>{
                    xAxisData.push(parseInt(graphObj[`${xAxisObj}`]))
                  })
                  graphObj['data'] = xAxisData;
                  return graphObj;
                })
                params["data"] = chartInfo;
                chartData = chartInfo;
                break;
              default:
                break;
            }
          }
          params[`${apiObj.key}`] = chartData;
          params[`${apiObj.key}_copy`] = [...tableInfo[apiObj.key]];
          formEditing[`${apiObj.key}_cluster`] = apiObj.action.clusters;
          formEditing[`isGraphDataUupdated`] = false;
          if (apiObj.isChartDynamic) {
            formEditing[`${apiObj.key}_chart`] = {
              yaxis: charts[index].yaxis,
              xaxis: charts[index].xaxis,
            };
          }
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });
        });
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });
    }
  }, [formEditedValues]);

  function setupGraphData(data, rawData) {
    data.forEach(obj => { obj.data = rawData.map(row => parseInt(row[obj.field])) })
    return data
  }
  return (
    <Grid container spacing={2}>
      {charts.map((obj) => (
        <>
          <Grid
            item
            xs={12}
            sm={gridLength}
            md={gridLength}
            lg={gridLength}
            xl={gridLength}
          >
            {obj.filters && <FilterSection filters={obj.filters} />}
            <Chart
            {...obj}
              chartType={obj.type}
              // stacking={obj.plotOptions.series.stacking}
              title={obj.title}
              yaxis={
                obj.isChartDynamic
                  ? formEditedValues[`${obj.key}_chart`]
                    ? formEditedValues[`${obj.key}_chart`].yaxis
                    : []
                  : obj.yaxis
              }
              xaxis={
                obj.isChartDynamic
                  ? formEditedValues[`${obj.key}_chart`]
                    ? formEditedValues[`${obj.key}_chart`].xaxis
                    : []
                  : obj.xaxis
              }
              showLegend={obj.showLegend}
              data={
                obj.key
                  ? tableInfo[`${obj.key}`]
                    ? obj?.importData ? setupGraphData(obj.data, tableInfo[`${obj.key}`])
                      : tableInfo[`${obj.key}`]
                    : []
                  : obj.data
              }
              stops={obj.stops}
              stacking={obj.plotOptions?.series?.stacking}
              colorAxisLabelFormats={obj.colorAxisLabelFormats}
            />
          </Grid>
        </>
      ))}
    </Grid>
  );
};
export default HorizontalCharts;
