import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Inventory2Outlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { getMultipleModelsData } from "../../pages/Home/action";

export default function CustomOrderKpiCard(props) {
  const [kpis, setKpis] = useState([]);
  const { data, toggle, values } = props;
  const entries = Object.entries(data).filter((obj) => obj[0] !== "header");
  const kpi = kpis.find(
    ({ identifier, type }) =>
      identifier === (toggle ? values.switch[1] : values.switch[0]) &&
      type === data.header
  );
  useEffect(async () => {
    const result = await getMultipleModelsData({
      model_names: [
        values.apiKey,
        // 'tb_order_mnt_kpis',
      ],
    });
    console.log(result[values.apiKey]);
    setKpis(result[values.apiKey]);
  }, [values.apiKey]);

  return kpis.length > 0 ? (
    <Grid container spacing={0}>
      {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}> */}
      <div
        className={`${styles["kpi-container"]} ${styles["flex-column"]}`}
        style={{ marginLeft: 0 }}
      >
        <div className={styles["flex-align-center"]}>
          <div className={styles["kpi-icon-container"]}>
            <Inventory2Outlined style={{ color: "#758398" }} />
          </div>
          <div className={styles["kpi-title"]}>{data.header}</div>
        </div>
        <div
          className={`${styles["kpi-details-container"]} ${styles["flex-align-end"]} ${styles["flex-justify-space-between"]}`}
        >
          <div className={`${styles["unit-wrapper"]} ${styles["flex"]}`}>
            {entries.map((obj) => (
              <div
                className={`${styles["unit-container"]} ${styles["flex-column"]}`}
              >
                <div className={styles["unit-label"]}>{obj[1]}</div>
                <div className={styles["unit-value"]}>{kpi[obj[0]]}</div>
              </div>
            ))}
          </div>
          <div
            className={`${styles["total-container"]} ${styles["flex-column"]} ${styles["flex-align-end"]}`}
          >
            <div className={styles["total-label"]}>Total</div>

            <div className={styles["total-value"]}>
              {toggle ? "$" : null}
              {new Intl.NumberFormat("en-US").format(
                entries.reduce(
                  (sum, obj) =>
                    sum + (parseInt(kpi[obj[0]]?.replace(/,/g, "")) || 0),
                  0
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </Grid> */}
    </Grid>
  ) : null;
}
