import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import moment from 'moment'


export default function ReactMuiDatePicker(props) {
  function handleChange(value) {
    if (props?.updateParentGrid) {
      props.setValue(value._d.toLocaleDateString())
    }
  }
  return (
    <LocalizationProvider 
    dateAdapter={AdapterMoment}
    >
      <DemoContainer
        components={[
          'DatePicker',
          // 'TimePicker',
          // 'DateTimePicker',
          // 'DateRangePicker',
        ]}
        sx={{paddingTop:0}}
      >
        <DatePicker
          onChange={handleChange}
          type="datetime-local"
          defaultValue={(new Date())}
           value={moment(props.value)} slotProps={{ textField: { size: 'small'}}}/>
        {/* </DemoItem> */}
      </DemoContainer>
    </LocalizationProvider>
  );
}
