import { useSelector } from "react-redux";
const funcs = {
  "*": (a, b) => a * b
}
export default function CellCompute(params) {
  const { tableInfo } = useSelector(state => state.home)
  const rows = tableInfo[params.parentTableKey]
  const field=params.colDef.field
  const a = parseFloat(rows.find(row => row.id == params.seq[0])[field])
  const b = parseFloat(rows.find(row => row.id == params.seq[2])[field])
  const output=funcs[params.seq[1]](a,b)
  return Number.isNaN(output) ? "" : Math.trunc(output).toString()
}
