import React from "react";
import {
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import { useState } from "react";
import { get } from "lodash";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0px",
    height: "500px",
    overflowY: "auto",
  },
  nested: {
    paddingLeft: "40px",
  },
}));

const Accordion = ({
  rows,
  rowInx,
  handleCheckbox,
  handleSubRowCheckbox,
  classes,
  handleChecked,
  type
}) => {
  const [open, setOpen] = useState(true);
  const mainGroupChecked = handleChecked(rows.data);
  return (
    <div>
      <ListItem dense key={rowInx}>
        <ListItemIcon>
          <Checkbox
            disableRipple
            edge="start"
            checked={mainGroupChecked}
            onChange={() =>
              handleCheckbox(rows.key, rows.label, mainGroupChecked,type)
            }
          />
        </ListItemIcon>
        <ListItemText primary={rows.label} />
        <ListItemSecondaryAction>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {rows?.subRows && (
        <Collapse in={open}>
          <List disablePadding component="div">
            {rows?.subRows.map((subRows, subRowInx) => (
              <ListItem dense className={classes.nested}>
                <ListItemIcon>
                  <Checkbox
                    disableRipple
                    edge="start"
                    checked={handleChecked(subRows.data)}
                    onChange={() =>
                      handleSubRowCheckbox(
                        rows.key,
                        rows.label,
                        subRows.key,
                        subRows.label
                      )
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={subRows.label} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
};

const groupByObj = (list, groupByKey) => {
  const groupByObj = list.reduce((initialObj, data) => {
    const initialObjData = get(initialObj, `${data[groupByKey]}.data`, []);
    initialObj[data[groupByKey]] = {
      key: groupByKey,
      data: [...initialObjData, data],
    };
    return initialObj;
  }, {});
  return Object.keys(groupByObj).map((key) => {
    return {
      label: key,
      key: groupByKey,
      data: groupByObj[key].data,
    };
  });
};

function AddHideMetrics(props) {
  const classes = useStyles();
  const GROUP_BY = ( props.groupBy || "metric" );
  const SUB_GROUP_BY = ( props.subGroupBy || "metric_level" )
  const SUB_METRIC_GROUP_LEVEL = (props.sub_metric_level || 'sub_metric_level')
  const IS_ACTIVE_GROUP_KEY = (props.isActiveGroupkey || 'metric_level')
  const [groupedRows, setGroupedRows] = useState([]);
  const [subGroupedRows, setSubGroupedRows] = useState([]);
  const updateState = () => {
    if(props.tableRef.current){
      const groupByMetricLabel = groupByObj(
        props.tableRef.current.props.rowData,
        GROUP_BY
      );
      const subGroupByMetricLabel = groupByObj(
        props.tableRef.current.props.rowData,
        SUB_METRIC_GROUP_LEVEL
      );
      const groupByMetric = groupByMetricLabel.map((data) => {
        return {
          ...data,
          subRows: groupByObj(data.data, SUB_GROUP_BY),
        };
      });
      setSubGroupedRows(subGroupByMetricLabel)
      setGroupedRows(groupByMetric);
    }
    
  };
  useEffect(() => {
    props.api.setAlwaysShowVerticalScroll(true);
    props.api.addEventListener("modelUpdated", updateState);

    return () => props.api.removeEventListener("modelUpdated", updateState);
  }, []);

  const handleCheckbox = (key, value, currentCheckedValue,type) => {
    props.tableRef.current.props.rowData.forEach((row) => {
      if(type === 'showHideOnOtherGroups'){
        const isGroupActive = props.tableRef.current.props.rowData.filter(obj=> obj[`${IS_ACTIVE_GROUP_KEY}`] === row[`${IS_ACTIVE_GROUP_KEY}`] && (obj.hide == '' || !obj.hide) )
        if (row[key] === value && isGroupActive.length > 0) {
          row.hide = currentCheckedValue;
        }
      }else if (row[key] === value) {
        row.hide = currentCheckedValue;
      }
    });
    props.tableRef.current.api.onFilterChanged();
    props.tableRef.current.api.refreshToolPanel();
  };
  const handleSubRowCheckbox = (
    parentKey,
    parentValue,
    childKey,
    childValue
  ) => {
    props.tableRef.current.props.rowData.forEach((row) => {
      if(props.subRowHideOnAllRows){
        if (row[childKey] === childValue) {
          row.hide = !row.hide;
        }
      }else{
        if (row[parentKey] === parentValue && row[childKey] === childValue) {
                row.hide = !row.hide;
              }
      }
      
      
    });
    props.tableRef.current.api.onFilterChanged();
    props.tableRef.current.api.refreshToolPanel();
  };
  const handleChecked = (list = []) => {
    const noHiddenList = list.filter((data) => !data?.hide);
    return noHiddenList.length !== 0;
  };
  return (
    <div className={classes.container}>
      <List disablePadding component="div">
      {props.metricTitle ? (<p> 
              &nbsp;&nbsp;&nbsp;&nbsp;<b>{props.metricTitle }</b> </p>) : null }
        {groupedRows.map((rows, rowInx) => (
          <Accordion
            rows={rows}
            rowInx={rowInx}
            handleCheckbox={handleCheckbox}
            handleSubRowCheckbox={handleSubRowCheckbox}
            handleChecked={handleChecked}
            classes={classes}
          />
        ))}
        {props.showHideOnOtherGroups ? (
          <>
            <hr />
            {props.subMetricTitle ? (<p> 
              &nbsp;&nbsp;&nbsp;&nbsp;<b>{props.subMetricTitle }</b> </p>) : null }
            {subGroupedRows.map((rows, rowInx) => (
              <>
               <Accordion
                rows={rows}
                rowInx={rowInx}
                handleCheckbox={handleCheckbox}
                handleSubRowCheckbox={handleSubRowCheckbox}
                handleChecked={handleChecked}
                classes={classes}
                type={"showHideOnOtherGroups"}
              />
            </>
            ))}
          </>
        ):null }
        
      </List>
    </div>
  );
}

export default AddHideMetrics;
