import { Step, StepButton, Stepper } from "@mui/material";

const Steppers = (props) => {
  return (
    <Stepper
      nonLinear={props.data.nonLinear?props.data.nonLinear.value:true}
      activeStep={props.data.activeStep}
      alternativeLabel={props.data.style ? false : true}
      style={props.data.style}
    >
      {props.data.steps.map((label, index) => (
        <Step key={label}>
          <StepButton>{label}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default Steppers;
