import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Table } from "../../components/Table";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import SelectRenderer from "../../components/Select/SelectRenderer";

const SplitTable = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState([{ label: "B1", value: "B1" }]);
  const [options, setOptions] = useState([
    { label: "A1", value: "A1" },
    { label: "A2", value: "A2" },
    { label: "A3", value: "A3" },
    { label: "B1", value: "B1" },
    { label: "B2", value: "B2" },
    { label: "B3", value: "B3" },
  ]);
  const {
    apiData,
    dependentInfo,
    tableInfo,
    selectedRowInfo,
    formEditedValues,
  } = useSelector((state) => state.home);

  useEffect(() => {
    if (formEditedValues[`split-table`]) {
      let reqParmas = {};
      const gradeCluster = tableInfo[`tb_grade_cluster_breakdown`];
      reqParmas[`tb_store_list`] = gradeCluster.filter(
        (obj) => obj.cluster === formEditedValues[`split-table`]
      );
      reqParmas["tb_store_list_next"] = gradeCluster.filter(
        (obj) => obj.cluster === value[0].label
      );
      console.log("REquest params", reqParmas);
      dispatch({
        type: "TABLE_DATA",
        payload: reqParmas,
      });
    }
  }, []);

  const addToLeft = () => {
    // tb_store_list_next to tb_store_list
    const storeList = tableInfo[`tb_store_list`];
    const selectedData = selectedRowInfo[`tb_store_list_next`];
    const storeIds = selectedData.map((obj) => obj.store_code);
    const store_list_next = tableInfo[`tb_store_list_next`].filter(
      (obj) => storeIds.indexOf(obj.store_code) === -1
    );
    let reqParmas = {};
    reqParmas[`tb_store_list`] = [...storeList, ...selectedData];
    reqParmas["tb_store_list_next"] = [...store_list_next];
    let selectedRowParams = {};
    selectedRowParams[`tb_store_list`] = [];
    selectedRowParams["tb_store_list_next"] = [];
    dispatch({
      type: "TABLE_DATA",
      payload: reqParmas,
    });
    dispatch({
      type: "SELECTED_ROWS",
      payload: selectedRowParams,
    });
  };
  const addToRight = () => {
    // tb_store_list to tb_store_list_next
    const store_list_next = tableInfo[`tb_store_list_next`];
    const selectedData = selectedRowInfo[`tb_store_list`];
    const storeIds = selectedData.map((obj) => obj.store_code);
    const store_list = tableInfo[`tb_store_list`].filter(
      (obj) => storeIds.indexOf(obj.store_code) === -1
    );
    let reqParmas = {};
    reqParmas[`tb_store_list_next`] = [...store_list_next, ...selectedData];
    reqParmas["tb_store_list"] = [...store_list];
    let selectedRowParams = {};
    selectedRowParams[`tb_store_list`] = [];
    selectedRowParams["tb_store_list_next"] = [];
    dispatch({
      type: "TABLE_DATA",
      payload: reqParmas,
    });
    dispatch({
      type: "SELECTED_ROWS",
      payload: selectedRowParams,
    });
  };
  const handleSelect = (params) => {
    console.log("parmas ", params);
    if (params.selectedItems.length > 0) {
      let reqParmas = {};
      const gradeCluster = tableInfo[`tb_grade_cluster_breakdown`];
      // reqParmas[`tb_store_list`] = gradeCluster.filter(obj=> obj.cluster === formEditedValues[`split-table`] );
      reqParmas["tb_store_list_next"] = gradeCluster.filter(
        (obj) => obj.cluster === params.selectedItems[0].label
      );
      dispatch({
        type: "TABLE_DATA",
        payload: reqParmas,
      });
    }
  };
  const defaultCoulmnDefination = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 80,
    },
    {
      field: "store_code",
      headerName: "Store",
      width: 180,
    },
    {
      field: "store_name",
      headerName: "Name",
      width: 200,
    },
  ];
  const defaultColDefStoreListNext = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 80,
    },
    {
      field: "store_code",
      headerName: "Store",
      width: 180,
    },
    {
      field: "store_name",
      headerName: "Name",
      width: 200,
    },
  ];
  return (
    <Grid container spacing={2}>
      <>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6subtitle2">
              <h4>
                { ( props.tables && props?.tables[0]?.title ) ||
                  `Selected Cluster ${formEditedValues[`split-table`]}`}
              </h4>
            </Typography>
          </div>
          <Table
            apikey={ (props?.tables && props?.tables[0]?.key) || "tb_store_list"}
            isStoreRequired={true}
            isClickBasedOnParent={true}
            isApiNotRequird={(props.tables && props?.tables[0]?.key ) ? false : true}
            tableCssStyle={"sizeColumnsToFit"}
            columnDefs={ (props.tables && props?.tables[0]?.column ) || defaultCoulmnDefination}
            floatingFilter={false}
            width={(props.tables &&  props?.tables[0]?.width ) || null}
            height={(props.tables && props?.tables[0]?.height ) || null}
            // height={"450px"}
            rowSelection={"multiple"}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              style={{ textTransform: "none", margin: 5 }}
              variant={"outlined"}
              size={""}
              onClick={addToLeft}
            >
              {"<<Add"}
            </Button>
            <Button
              style={{ textTransform: "none", margin: 5 }}
              variant={"outlined"}
              size={""}
              onClick={addToRight}
            >
              {"Remove>>"}
            </Button>
          </div>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <div>
            <Typography variant="h6subtitle2">
              <h4>{(props.tables && props?.tables[1].title ) || null }</h4>
            </Typography>
            {props?.tables === undefined && (
              <SelectRenderer
                options={options}
                selectedItems={value}
                isMulti={false}
                // width="12rem"
                filterLabel={"Transfer from "}
                horizontalView={true}
                updateSelected={(params) => {
                  handleSelect(params);
                }}
              />
            )}
          </div>
          {props?.tables === undefined && <div className="spacer"></div>}
          {props?.tables === undefined && <div className="spacer"></div>}
          {props?.tables === undefined && <div className="spacer"></div>}

          <Table
            apikey={(props.tables && props?.tables[1]?.key) || "tb_store_list_next"}
            isStoreRequired={true}
            isApiNotRequird={(props.tables && props?.tables[1]?.key ) ? false : true}
            isClickBasedOnParent={true}
            tableCssStyle={"sizeColumnsToFit"}
            columnDefs={(props.tables &&  props?.tables[0]?.column ) || defaultColDefStoreListNext}
            floatingFilter={false}
            // height={"450px"}
            rowSelection={"multiple"}
          />
        </Grid>
      </>
    </Grid>
  );
};
export default SplitTable;
