import axios from "axios";

const apiJson = {
	dev: {
		generic: "http://127.0.0.1:25250",
	},
	carters: {
		"inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/carters/inventory/api/v1/",
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/carters/assort/api/v1/",
		"price-smart":
			"https://play.products.impactsmartsuite.com/carters/price/api/v1",
		"price-smart-carters":
			"https://sandbox.products.impactsmartsuite.com/carters/price/api/v1/",
	},
	ulta: {
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/ulta/assort/api/v1/",

		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/ulta/plan/api/v1/",
	},
	rl: {
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/rl/assort/api/v1/",
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/rl/plan/api/v1/",
		"space-smart":
			"https://sandbox.products.impactsmartsuite.com/rl/space/api/v1/",
	},
	levis: {
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/levis/assort/api/v1/",
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/levis/plan/api/v1/",
	},
	homedepot: {
		"cluster-smart":
			"https://sandbox.inventorysmart.impactsmartsuite.com/homedepot/cluster/api/v1/",
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/homedepot/assort/api/v1/",
	},
	gs1: {
		ada: "https://sandbox.products.impactsmartsuite.com/gs1/ada/api/v1/",
	},
	ecom: {
		ada: "https://sandbox.products.impactsmartsuite.com/ecom/ada/api/v1/",
	},
	grocery: {
		"trade-smart":
			"https://sandbox.products.impactsmartsuite.com/grocery/trade/api/v1/",
		"assort-smart-grocery":
			"https://sandbox.products.impactsmartsuite.com/grocery/assort/api/v1/",
		"grocery-price-smart":
			"https://sandbox.products.impactsmartsuite.com/grocery/price/api/v1/",
		"grocery-inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/grocery/inventory/api/v1/",
	},
	"altitude-sports": {
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/altitude-sports/plan/api/v1/",
	},
	costco: {
		"promo-smart":
			"https://sandbox.products.impactsmartsuite.com/costco/promo/api/v1/",
	},
	twc: {
		"promo-smart":
			"https://sandbox.products.impactsmartsuite.com/twc/promo/api/v1/",
	},
	partycity: {
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/partycity/plan/api/v1/",

		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/partycity/assort/api/v1/",
		ada: "https://sandbox.products.impactsmartsuite.com/partycity/ada/api/v1/",
	},
	generic: {
		"track-smart":
			"https://sandbox.products.impactsmartsuite.com/generic/track/api/v1/",
		"trade-smart":
			"https://sandbox.products.impactsmartsuite.com/generic/trade/api/v1/",
		"inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/generic/inventory/api/v1/",
		"old-inventory":
			"https://sandbox.inventorysmart.impactsmartsuite.com/api/v1/",
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/levis/plan/api/v1/",
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/levis/assort/api/v1/",
		"price-smart":
			"https://sandbox.products.impactsmartsuite.com/generic/price/api/v1/",
		"ootb-inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/ootb/inventory/api/v1/",
		"ootb-inventory-smart-vs":
		"https://sandbox.products.impactsmartsuite.com/ootbvs/ootb-inventory-smart-vs/api/v1/"

	},
	chicos: {
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/chicos/plan/api/v1/",
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/chicos/assort/api/v1/",
		"chicos-inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/chicos/inventory/api/v1/",
		"inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/chicos/old-inventory/api/v1/",
	},
	arhaus: {
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/arhaus/assort/api/v1/",
		"plan-smart-arhaus":
			"https://sandbox.products.impactsmartsuite.com/arhaus/plan/api/v1/",
	},
	dg: {
		"dg-inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/dg/inventory/api/v1/",
	},
	joann: {
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/joann/plan/api/v1/",
	},
	skf: {
		ada: "https://sandbox.products.impactsmartsuite.com/skf/ada/api/v1/",
	},
	gelson: {
		"promo-smart":
			"https://sandbox.products.impactsmartsuite.com/gelson/promo/api/v1/",
	},
	chatbot: {
		"ada-chatbot":
			"https://dev.products.impactsmartsuite.com/chatbot/ada/api/v1/",
	},
	"tommy-bahama": {
		"assort-smart-tb":
			"https://sandbox.products.impactsmartsuite.com/tommybahama/assort/api/v1/",
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/tommy-bahamas/plan/api/v1/",
	},
	lululemon: {
		"assort-smart-lululemon":
			"https://sandbox.products.impactsmartsuite.com/lululemon/assort/api/v1/",
	},
	MnS: {
		ada: "https://sandbox.products.impactsmartsuite.com/MnS/ada/api/v1/",
	},
	unieuro: {
		"promo-smart":
			"https://sandbox.products.impactsmartsuite.com/costco/promo/api/v1/",
	},
	swisher: {
		"promo-smart":
			"https://sandbox.products.impactsmartsuite.com/swisher/promo/api/v1/",
	},
	zumiez: {
		"assort-smart":
			"https://sandbox.products.impactsmartsuite.com/zumiez/assort/api/v1/",
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/zumiez/plan/api/v1/",
	},
	vs: {
        "ootb-inventory-smart-vs":
            "https://sandbox.products.impactsmartsuite.com/ootbvs/ootb-inventory-smart-vs/api/v1/",
    },
	jcpenny: {
		"inventory-smart":
			"https://sandbox.products.impactsmartsuite.com/jcpenny/inventory/api/v1/",
	},
	"vitamin-shoppe": {
		"plan-smart":
			"https://sandbox.products.impactsmartsuite.com/vitamin-shoppe/plan/api/v1/",
	},
};

console.log(
	"requested api url =>",
	apiJson[window?.location?.pathname?.split("/")[1]][
		window?.location?.pathname?.split("/")[2]
	],
);
const callApi = axios.create({
	baseURL:
		apiJson[window?.location?.pathname?.split("/")[1]][
			window?.location?.pathname?.split("/")[2]
		],
	withCredentials: true,
});

console.log("window loc", window.location);

callApi.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		console.log("request fails with error", error.message);
		return Promise.reject(error);
	},
);

callApi.interceptors.response.use(
	function (res) {
		return res;
	},
	async function (error) {
		const {
			response: { status },
		} = error;
		console.log(error, status);
		console.log("response fails with error", error.message, error.config);
		// return error;
	},
);

export default callApi;
