import {
  TextField,
  InputLabel,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Text from "../../utils/Text";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const styles = (theme) => ({
  inputLabel: {
    lineHeight: "1.5",
    color: "#758490",
    fontSize: "12px",
  },
  disabled: {
    backgroundColor: "#C7E4FD !important",
  },
  textFieldDisabled: {
    backgroundColor: "#B6B6B6 !important",
  },
});
const useStyles = makeStyles(styles);

const TextFieldWithLabel = (props) => {
  const classes = useStyles();
  const inputProps = props.inputProps || { max: 99, min: 1 };
  const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			if (props?.clearDependentsOnDestroy) {
				dispatch({
					type: "FORM_EDITED_VALUES",
					payload: { [props.name]: null },
				});
			}
		};
	}, []);
  return (
    <>
      {props.horizontalView ? (
        <>
          <Grid container>
            <Grid item xs>
              <Text
                style={{
                  marginRight: "10px",
                  fontSize: "12px",
                  color: "#758490",
                }}
              >
                {props?.label}
              </Text>
            </Grid>
            <Grid item xs>
              <TextField
                InputProps={{
                  sx: {
                    height: 40,
                    width: props.width,
                    fontSize: "13px",
                    fontFamily:
                      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif",
                  },
                  endAdornment: props.endAdornment,
                  inputProps: inputProps,
                }}
                hiddenLabel
                size="small"
                id="filled-hidden-label-normal"
                variant="outlined"
                value={props.value}
                onChange={props.onChange}
                onBlur={props.handleOnBlur}
                defaultValue={props.defaultValue}
                name={props.name}
                type={props.type}
                disabled={props.disabled}
                // {...props}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <div>
          {props.label ? (
            <>
              <Text
                className={classes.inputLabel}
                required={props.required}
                placeholder={props?.label}
              >
                <span title={props.label}>{props.label}</span>
                {props.isImportant && (
                  <span style={props?.isImportantStyle}> *</span>
                )}
              </Text>
            </>
          ) : null}
          <TextField
            InputProps={{
              sx: {
                height: 30,
                width: props.width,
                fontSize: "13px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif",
                color:props.color,
                backgroundColor:(props.backgroundColor),
                ...props.style
              },
              endAdornment: props.endAdornment,
              inputProps: inputProps,
              startAdornment: props.startAdornment,
            }}
            hiddenLabel
            size="small"
            id="filled-hidden-label-normal"
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            onBlur={props.handleOnBlur}
            defaultValue={props.defaultValue}
            name={props.name}
            type={props.type}
            disabled={props.disabled}
              className={props.className}
              onKeyDown={props.onKeyPress}
            // {...props}
          />
        </div>
      )}
    </>
  );
};

export default TextFieldWithLabel;
