import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Preview from "../pages/Preview";
import MapWrapper from "../openlayers/components/MapWrapper";
import {
  fetchFilterDataAction,
  fetchFormDataAction,
  getMultipleModelsData,
} from "../pages/Home/action";
import { useDispatch, useSelector } from "react-redux";
import ImpactMap from "../openlayers/components/ImpactMap";
import technical_issue_img from "../assets/images/technical_issues.png";

export const AppRoutingModule = () => {
  const [pageConfig, setPageConfig] = useState([]);
  const [techError, setTechError] = useState(false);
  const dispatch = useDispatch();
  const { formMasterValuesData, filterMenuData } = useSelector(
    (state) => state.filter
  );
  const getConfigData = async () => {
    const pages = await getMultipleModelsData({ model_names: ["tb_pages"] });
    // These values coming from Google sheets
    console.log("PAGES_CONFIG", pages);
    if (!pages?.err) {
      if (formMasterValuesData.length === 0) {
        dispatch(
          fetchFormDataAction({
            model_names: ["tb_form_master", "tb_form_master_values"],
          })
        );
      }
      if (filterMenuData.length === 0) {
        dispatch(
          fetchFilterDataAction({
            model_names: ["tb_filter_master_copy", "tb_filter_master_values"],
          })
        );
      }
      setPageConfig(pages.tb_pages);
    } else {
      setTechError(true);
    }
  };

  useEffect(() => {
    getConfigData();
  }, []);
  return (
    <div style={{ height: "100vh" }}>
      {techError ? (
        <div className="tech-error">
          <img src={technical_issue_img} alt="error" />
        </div>
      ) : (
        <>
          <Router>
            <Routes>
              {pageConfig?.map((item) => {
                // Adding routes from google sheets tb_pages

                return (
                  <Route
                    key={item.url}
                    path={`/:client/:product/${item.url}`}
                    element={<Home pageConfig={item} />}
                  />
                );
              })}
              <Route path={`/:client/:product/preview`} element={<Preview />} />
              <Route path={"map"} element={<MapWrapper />} />
              <Route path={"store-map"} element={<ImpactMap />} />
            </Routes>
          </Router>
        </>
      )}
    </div>
  );
};
