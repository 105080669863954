import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import SelectRenderer from "../../components/Select/SelectRenderer";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
const styles = (theme) => ({
  loaderContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: "100%",
    height: "100%",
  },
  loader: {
    color: theme.palette.bgSwitch,
    margin: 20,
  },
  headerCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const FilterSection = ({ filters }) => {
  const dispatch = useDispatch();
  const {
    selectedRowInfo,
    tableInfo,
    editActionInfo,
    dependentInfo,
    columnsInfo,
    formEditedValues,
  } = useSelector((state) => state.home);
  const classes = useStyles();
  const [value, setValue] = useState("");
  const handleSelect = (params, selectInfo) => {
    console.log("Params selected", params, selectInfo);
    let chartInfo = [];
    const selectedValues = params.selectedItems.map(
      (filterObj) => filterObj.label
    );
    let chartData = [];
    let groupedData = {};
    let selectParams = {};
    let formEditing = {};
    if (selectInfo.action) {
      switch (selectInfo.action.type) {
        case "ATTRIBUTE_GRADES":
          chartInfo = [...tableInfo[`${selectInfo.action.sourceKey}`]];
          chartData = [];
          if(selectedValues.length === 2){
            chartInfo = chartInfo.filter(
              (obj) => selectedValues.indexOf(obj.attribute_type) === -1 
            );
          }else{
            chartInfo = chartInfo.filter(
              (obj) => selectedValues.indexOf(obj.attribute_type) !== -1 
            );
          }
          
		  const AttributeGradeClusters = Array.from({
			  length:
			  formEditedValues[`${selectInfo.action.destinationKey}_cluster`],
        },
        (_, i) => String.fromCharCode(64+ (i+1))
        ).concat("Average");
          groupedData = _.groupBy(chartInfo, "name");
          if (Object.keys(groupedData).length > 0) {
            Object.keys(groupedData).forEach((obj) => {
              let params = { ...groupedData[`${obj}`][0] };
              params["data"] = groupedData[`${obj}`].filter(filterObj=> AttributeGradeClusters.indexOf(filterObj.labels) !== -1  ).map((obj) =>
                parseInt(obj.value)
              );
              chartData.push(params);
            });
          }
          selectParams[`${selectInfo.action.destinationKey}`] = chartData;
		      formEditing[`${selectInfo.action.destinationKey}_grades`] = selectedValues;
		  dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });
          dispatch({
            type: "TABLE_DATA",
            payload: selectParams,
          });
          break;
		    case "ATTRIBUTE_GRADES_CLUSTERS":
			chartInfo = [...tableInfo[`${selectInfo.action.sourceKey}`]];
			chartData = [];
			const clusters = Array.from({
				length:
				parseInt(selectedValues[0]),
			  },
			  (_, i) => String.fromCharCode(64+ (i+1))
			).concat("Average");
      console.log("&&&&&&&&&&&&&",chartInfo,selectedValues,clusters)
			chartInfo = chartInfo.filter(
			  (obj) => clusters.indexOf(obj.labels) !== -1 && formEditedValues[
                `${selectInfo.action.destinationKey}_grades`
              ].indexOf(obj.attribute_type) !== -1
			);
			groupedData = _.groupBy(chartInfo, "name");
			if (Object.keys(groupedData).length > 0) {
			  Object.keys(groupedData).forEach((obj) => {
				let params = { ...groupedData[`${obj}`][0] };
				params["data"] = groupedData[`${obj}`].filter(filterObj=> clusters.indexOf(filterObj.labels) !== -1  ).map((obj) =>
				  parseInt(obj.value)
				);
				chartData.push(params);
			  });
			}
			selectParams[`${selectInfo.action.destinationKey}`] = chartData;
			formEditing[`${selectInfo.action.destinationKey}_cluster`] = selectedValues;
			formEditing[`${selectInfo.action.destinationKey}_chart`] = {
				...formEditedValues[`${selectInfo.action.destinationKey}_chart`],
				xaxis: {
				title: formEditedValues[`${selectInfo.action.destinationKey}_chart`].xaxis.title,
				categories: clusters,
				}
			};
			dispatch({
			  type: "TABLE_DATA",
			  payload: selectParams,
			});
			dispatch({
				type: "FORM_EDITED_VALUES",
				payload: formEditing,
			  });
			break;
		    case "PERFORMANCE_CLUSTERS_XAXIS":
          chartInfo = [...tableInfo[`${selectInfo.action.sourceKey}`]];
          const clusterNames = Array.from(
            {
              length:
                formEditedValues[`${selectInfo.action.destinationKey}_cluster`],
            },
            (_, i) => `Cluster ${i + 1}`
          );
          const yAxis =
            formEditedValues[`${selectInfo.action.destinationKey}_yAxis`];
          chartInfo = chartInfo.filter(
            (obj) =>
              selectedValues.indexOf(obj.xAxis) !== -1 &&
              clusterNames.indexOf(obj.name) !== -1 &&
              yAxis.indexOf(obj.yAxis) !== -1
          );
          groupedData = _.groupBy(chartInfo, "name");
          if (Object.keys(groupedData).length > 0) {
            Object.keys(groupedData).forEach((obj) => {
              let params = {
                name: groupedData[`${obj}`][0].name,
                color: groupedData[`${obj}`][0].color,
              };
              let clusterValues = [];
              groupedData[`${obj}`].forEach((groupObj) => {
                clusterValues.push([
                  parseFloat(groupObj.xAxisValue),
                  parseFloat(groupObj.yAxisValue),
                ]);
              });
              params["data"] = clusterValues;
              chartData.push(params);
            });
          }

          selectParams[`${selectInfo.action.destinationKey}`] = chartData;
          dispatch({
            type: "TABLE_DATA",
            payload: selectParams,
          });

          formEditing[`${selectInfo.action.destinationKey}_xAxis`] =
            selectedValues;
          formEditing[`${selectInfo.action.destinationKey}_chart`] = {
            ...formEditedValues[`${selectInfo.action.destinationKey}_chart`],
            xaxis: {
              title: selectedValues[0],
              categories: clusterNames,
            },
          };
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });

          break;
        case "PERFORMANCE_CLUSTERS_CLUSTERS":
          chartInfo = [...tableInfo[`${selectInfo.action.sourceKey}`]];
          let clusterList = Array.from( { length: parseInt(selectedValues[0]) }, (_, i) => `Cluster ${i + 1}`);
          chartInfo = chartInfo.filter(
            (obj) =>
              clusterList.indexOf(obj.name) !== -1 
              &&
              formEditedValues[
                `${selectInfo.action.destinationKey}_xAxis`
              ].indexOf(obj.xAxis) !== -1 &&
              formEditedValues[
                `${selectInfo.action.destinationKey}_yAxis`
              ].indexOf(obj.yAxis) !== -1
          );
          groupedData = _.groupBy(chartInfo, "name");
          if (Object.keys(groupedData).length > 0) {
            Object.keys(groupedData).forEach((obj) => {
              let params = {
                name: groupedData[`${obj}`][0].name,
                color: groupedData[`${obj}`][0].color,
              };
              let clusterValues = [];
              groupedData[`${obj}`].forEach((groupObj) => {
                clusterValues.push([
                  parseFloat(groupObj.xAxisValue),
                  parseFloat(groupObj.yAxisValue),
                ]);
              });
              params["data"] = clusterValues;
              chartData.push(params);
            });
          }

          selectParams[`${selectInfo.action.destinationKey}`] = chartData;
          dispatch({
            type: "TABLE_DATA",
            payload: selectParams,
          });

          formEditing[`${selectInfo.action.destinationKey}_cluster`] =
            selectedValues;
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });

          break;
        case "PERFORMANCE_CLUSTERS_YAXIS":
          chartInfo = [...tableInfo[`${selectInfo.action.sourceKey}`]];
          const clusterNamesList = Array.from(
            {
              length:
                formEditedValues[`${selectInfo.action.destinationKey}_cluster`],
            },
            (_, i) => `Cluster ${i + 1}`
          );
          chartInfo = chartInfo.filter((obj) => {
            return (
              formEditedValues[
                `${selectInfo.action.destinationKey}_xAxis`
              ].indexOf(obj.xAxis) !== -1 &&
              clusterNamesList.indexOf(obj.name) !== -1 &&
              selectedValues.indexOf(obj.yAxis) !== -1
            );
          });
          groupedData = _.groupBy(chartInfo, "name");
          if (Object.keys(groupedData).length > 0) {
            Object.keys(groupedData).forEach((obj) => {
              let params = {
                name: groupedData[`${obj}`][0].name,
                color: groupedData[`${obj}`][0].color,
              };
              let clusterValues = [];
              groupedData[`${obj}`].forEach((groupObj) => {
                clusterValues.push([
                  parseFloat(groupObj.xAxisValue),
                  parseFloat(groupObj.yAxisValue),
                ]);
              });
              params["data"] = clusterValues;
              chartData.push(params);
            });
          }

          selectParams[`${selectInfo.action.destinationKey}`] = chartData;
          dispatch({
            type: "TABLE_DATA",
            payload: selectParams,
          });

          formEditing[`${selectInfo.action.destinationKey}_yAxis`] =
            selectedValues;
          formEditing[`${selectInfo.action.destinationKey}_chart`] = {
            //    ...formEditedValues[`${selectInfo.action.destinationKey}_chart`],
            yaxis: {
              title: selectedValues[0],
            },
            xaxis: {
              title: selectedValues[0],
              categories: clusterNamesList,
            },
          };
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });
        
        default:
          break;
      }
    }
  };
  return (
    <span style={{ display: "flex", margin: 5 }}>
      {filters.label && <>{filters.label}</>}
      {filters.setions.map((item) => (
        <div style={{ margin: "1rem" }}>
          <SelectRenderer
            options={item.options}
            selectedItems={item.value}
            isMulti={item.isMulti}
            width="12rem"
            filterLabel={item.label}
            updateSelected={(params) => {
              handleSelect(params, item);
            }}
          />
        </div>
      ))}

      {filters.filterButtons && (
        <div style={{ margin: "2.4rem" }}>
          <Button
            style={{ marginLeft: 5, bottom: 0 }}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
          <Button
            style={{ marginLeft: 5, bottom: 0 }}
            size="small"
            variant="outlined"
          >
            Reset
          </Button>
        </div>
      )}
    </span>
  );
};

export default FilterSection;
