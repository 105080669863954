import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { ENABLE_EDIT_OPTIONS, MONTH_LEVEL_COLUMNS } from "../data";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@mui/styles";
import LockOpenIcon from '@mui/icons-material/LockOpen';
const styles = (theme) => ({
  inputLabel: {
    lineHeight: "1.5",
    color: "#8596a9",
    fontSize: "0.9rem",
    marginBottom: "0.2rem",
  },
  disabled: {
    backgroundColor: "#C7E4FD !important",
    color: "#C7E4FD !important",
  },
  textFieldDisabled:{
    // backgroundColor:"#B6B6B6 !important"
    // color:"#B6B6B6 !important"
  }
});
const useStyles = makeStyles(styles);

export const EditWeekData = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [updatedValue, setUpdatedValue] = useState({});
  const { selectedRowInfo, tableInfo, editActionInfo, dependentInfo } =
    useSelector((state) => state.home);

  const handleInputChange = (e) => {
    const { label, value } = e.target;
    const updateObj = {
      currentRow: props.data,
      field: props.colDef.field,
      value: value,
      rowData: props.rowData,
      rowIndex: parseInt(props.data.id) - 1,
    };
    setUpdatedValue(updateObj);
  };
  const handleOnBlur = () => {
    // props.handleCellChange(updatedValue);
    const { field, value, currentRow, rowIndex } = updatedValue;
    let planType = "";
    if(props.parent_table_key.indexOf("_wholesale") !==-1 && props.parent_table_key.endsWith("_wholesale")){
      planType = "_wholesale"
    }else if(props.parent_table_key.indexOf("_totorg") !==-1 && props.parent_table_key.endsWith("_totorg") ){
      planType = "_totorg"
    }

    if (Object.keys(updatedValue).length > 0) {
      let params = {};
      if (currentRow[`ref`] === "Contribution %") {
        params[`tb_plan_department`] = [
          ...tableInfo["tb_plan_department_contribution"],
        ];
      }
      if (
        currentRow[`ref`] === "Var LY%" ||
        currentRow[`ref`] === "Variance IAF (%)"
      ) {
        params[`${props.parent_table_key}`] = [...tableInfo[`tb_plan_department_variance_ly_and_iaf${planType}`]];
        
      }
      if (
        currentRow[`ref`] === "WP" &&
        props.colDef.field === props.changeOnTotalKey && currentRow['metric_level'] === props.changeOnMetricLevel
        
      ) {
        // const metricData = [...tableInfo["tb_plan_department_locked_celldata"]];
        const metricData = [...tableInfo[`tb_plan_department_locked_celldata${planType}`]];
        metricData.map((obj) => {
          if (obj["metric_level"] === currentRow.metric_level) {
            obj[`${props.changeOnLockedKey}`] = true;
          }
        });
        let params = {};
        params[`${props.parent_table_key}`] = [...metricData];
        // const planDepartmentData = tableInfo["tb_ plan_department"].filter(obj=> obj[``])
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });
        // let dependentKeys = {};
        // dependentKeys[`${props.colDef.field}`] = dependentInfo[
        //   `${props.colDef.field}`
        // ]
        //   ? false
        //   : true;
        // dispatch({
        //   type: "DEPENDENT_COMPONENTS",
        //   payload: dependentKeys,
        // });
      }else if (currentRow[`ref`] === "WP" && currentRow['metric_level'] === props.changeOnMetricLevel) {
      // else if (currentRow[`ref`] === "WP" && currentRow['metric_level'] === 'Total Net Sales $') {
        // params[`tb_plan_department`] = [...tableInfo["tb_plan_department_wp_change"]];
        params[`${props.parent_table_key}`] = [...tableInfo[`tb_plan_department_wp_change${planType}`]];
      }else if (currentRow[`ref`] === "WP" && currentRow['metric_level'] ==='Receipt U') {
          // params[`tb_plan_department`] = [...tableInfo["tb_plan_department_receipt_change_celldata"]];
          if(planType === '_wholesale'){
            params[`${props.parent_table_key}`] = [...tableInfo[`tb_plan_department_receipt_change_celldata_wholesa`]];
          }else{
            params[`${props.parent_table_key}`] = [...tableInfo[`tb_plan_department_receipt_change_celldata${planType}`]];
          }
        }else if (currentRow[`ref`] === "WP" && currentRow['metric_level'] ==='Markdown %') {
          // params[`tb_plan_department`] = [ ...tableInfo["tb_plan_department_MD%_change_celldata"]];
          params[`${props.parent_table_key}`] = [...tableInfo[`tb_plan_department_MD%_change_celldata${planType}`]];
        } else if (currentRow[`ref`] === "WF" && currentRow['metric_level'] ==='Receipt U' && props.colDef.field === 'summer_may_total') {
          params[`tb_inseason_department`] = [...tableInfo["tb_inseason_department_receipts_change"]];
        } else if (currentRow[`ref`] === "Actuals/WF" && currentRow['metric_level'] ==='Markdown %' && props.colDef.field === 'summer_total') {
          params[`tb_inseason_department`] = [
            ...tableInfo["tb_inseason_department_md"],
          ];
        } else if (currentRow[`ref`] === "Actuals/WF" && currentRow['metric_level'] ==='Net Sales AUR' && props.colDef.field === 'summer_may_total') {
          params[`tb_inseason_department`] = [
            ...tableInfo["tb_inseason_department_aur"],
          ];
        } else if (currentRow[`ref`] === "Actuals/WF" && currentRow['metric_level'] ==='Receipt Cost $' && props.colDef.field === 'summer_may_total') {
          params[`tb_inseason_department`] = [...tableInfo["tb_inseason_department_otb"]];
        } else if (currentRow[`ref`] === "WP" && currentRow['metric_level'] ==='WH Sell In $' && props.colDef.field === 'spring_mar_total') {
          // params[`tb_plan_department`] = [...tableInfo["tb_plan_department_wholesale_seed"]];
          params[`${props.parent_table_key}`] = [...tableInfo[`tb_plan_department_wholesale_seed${planType}`]];
        }
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
      /*
      let updateObj = {};
      let copyTableData = [...tableInfo[`${props.parent_table_key}`]];
      updateObj[`${field}`] = value;
      console.log("Current Row", currentRow,currentRow[`ref`] === "Var LY%",rowIndex);

      if (currentRow[`ref`] === "Contribution %") {
        const oldValue = copyTableData[`${rowIndex}`][`${field}`];
        const contriDiff = value - oldValue;
        let diffValue = 3 / contriDiff;
        MONTH_LEVEL_COLUMNS.filter((col) => {
          const columnNames = field.split("_");
          console.log("colum Name", columnNames);
          const firstPrefx = columnNames[0];
          const secondPrefx = columnNames[1];
          let wpRowIndex = rowIndex - 1;
          let colName = `${firstPrefx}_${secondPrefx}_${col}`;
          if (columnNames[2] !== col) {
            copyTableData[`${rowIndex}`][`${colName}`] =
              copyTableData[`${rowIndex}`][`${colName}`] - diffValue;
            if (col !== "total") {
              const totalValue = `${firstPrefx}_${secondPrefx}_total`;
              copyTableData[`${wpRowIndex}`][`${colName}`] = parseInt(
                (copyTableData[`${wpRowIndex}`][`${totalValue}`] *
                  copyTableData[`${rowIndex}`][`${colName}`]) /
                  100
              );
            }
          } else {
            const totalValue = `${firstPrefx}_${secondPrefx}_total`;
            copyTableData[`${wpRowIndex}`][`${colName}`] = parseInt(
              (copyTableData[`${wpRowIndex}`][`${totalValue}`] * value) / 100
            );
          }
        });
      }
      if (currentRow[`ref`] === "Var LY%") {

        const oldValue = copyTableData[`${rowIndex}`][`${field}`];
        const contriDiff = value - oldValue;
        console.log("Old value", oldValue, field);
        const columnNames = field.split("_");
        console.log("colum Name", columnNames);
        const firstPrefx = columnNames[0];
        const secondPrefx = columnNames[1];
        let wpRowIndex = rowIndex - 4;
        let LYRowIndex = rowIndex - 1;
        const totalValue = `${firstPrefx}_${secondPrefx}_total`;
        const seasonName = `${firstPrefx}_total`;
        const fieldValue = 1 + `.0${value}`;
        const LyValue = parseInt(
          copyTableData[`${LYRowIndex}`][`${field}`] * fieldValue
        );
        const wpOldValue = copyTableData[`${wpRowIndex}`][`${field}`];
        const valueDiff = LyValue - parseInt(wpOldValue);
        copyTableData[`${wpRowIndex}`][`${field}`] = LyValue;
        copyTableData[`${wpRowIndex}`][`${totalValue}`] =
          parseInt(copyTableData[`${wpRowIndex}`][`${totalValue}`]) + valueDiff;
        copyTableData[`${wpRowIndex}`][`${seasonName}`] =
          parseInt(copyTableData[`${wpRowIndex}`][`${seasonName}`]) + valueDiff;
        copyTableData[`${wpRowIndex}`][`year_total`] =
          parseInt(copyTableData[`${wpRowIndex}`][`year_total`]) + valueDiff;
      }

      copyTableData[`${rowIndex}`][`${field}`] = value;
      let payload = {};
      payload[`${props.parent_table_key}`] = copyTableData;
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
      */
    }
  };
  const handleLockOption = (rowData) => {
    // const metricData = tableInfo["tb_plan_department_locked_celldata"]
    // const metricData = tableInfo["tb_plan_department"];
    const metricData = tableInfo[`${props.parent_table_key}`]
    metricData.map((obj) => {
      if (obj["metric_level"] === rowData.metric_level) {
        // obj[`${props.colDef.field}_locked`] = obj[`${props.colDef.field}_locked`] ? false : true;
        obj[`${props.colDef.field}_locked`] = dependentInfo[`${props.colDef.field}`] ? false : true;
      }
      if(obj["metric_level"] !== rowData.metric_level && obj['ref'] === rowData.ref){
        // obj[`${props.colDef.field}_locked`] =  true;
        obj[`${props.colDef.field}_locked`] =   dependentInfo[`${props.colDef.field}`] ? false : true
      }
    });
    console.log("Metric Data", metricData);
    let params = {};
    // params[`tb_plan_department`] = [...metricData];
    params[`${props.parent_table_key}`] = [...metricData];
    
    // const planDepartmentData = tableInfo["tb_ plan_department"].filter(obj=> obj[``])
    dispatch({
      type: "TABLE_DATA",
      payload: params,
    });
    let dependentKeys = {};
    dependentKeys[`${props.colDef.field}`] = dependentInfo[`${props.colDef.field}`] ? false  : true;
    dispatch({
      type: "DEPENDENT_COMPONENTS",
      payload: dependentKeys,
    });
    /*
    const metricData = tableInfo[`${props.parent_table_key}`];
    metricData.map(obj=>{
        if(obj["metric_level"] === rowData.metric_level){
          console.log("Mettic",obj.metric_level,props.colDef.field)
          obj[`${props.colDef.field}_locked`] = obj[`${props.colDef.field}_locked`] ? false : true;
        }
    })
    console.log("Locked Data",metricData)
    let payload = {};
      payload[`${props.parent_table_key}`] = metricData;
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
      */
  };
  
  
  const rowGroupValue = ()=>{
    if(tableInfo[`${props.parent_table_key}`]){
      const tableData = tableInfo[`${props.parent_table_key}`].filter(obj=> obj[`${props.node.field}`] === props.node.key &&  obj[`${props.conditionKey}`] === props.displayAggValue )[0]
      return tableData ? tableData[`${props.mappingKey}`] : ''
    }else{
      return ''
    }
  }

  const getColor = ()=>{
    const {
      value,
      colDef: { colorCode,rowMappingKey,rowValue },
    } = props;

    if(props.value){
      if(props.value && rowValue && rowValue.indexOf(props.data[`${rowMappingKey}`])!==-1){
        let found = null;
          if (colorCode.type === "range") {
            const _value = value.includes('%') ? parseInt(value.replace(/,|%/g, "")) : (isNaN(value) ? value :  parseInt(value) ) ;
            found = colorCode.cases.find(
              ({ min, max }) => {
                return _value >= min && _value <= max
              }
            );
          } else {
            found = colorCode.cases.find((obj) => obj.value === value);
          }
          // return {color: 'red', backgroundColor: 'green'};
          return { color: found ? found.color : colorCode?.default };
      }
      return {}
    }
    return {}
    
  }
  return (
    <>
    { props.node.level === 1 && false ? 
    <>
      {rowGroupValue()}
    </>
     : (
      <>
        {props.data &&
      props.metricKey &&
      ( ( ENABLE_EDIT_OPTIONS.includes(props.data[`${props.metricKey}`]) &&
      props.conditionValues.indexOf(props.data[`${props.conditionKey}`]) ===
        -1 ) || props.data['isEditable']) ? (
        <>
          {["WP", "IA Forecast", "LY","New IA Forecast"].includes(props.data[`ref`]) ? (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextFieldWithLabel
                      type="text"
                      onChange={handleInputChange}
                      defaultValue={( props.value || props.data[`${props.mappingKey}`] )}
                      handleOnBlur={handleOnBlur}
                      startAdornment={
                        props.data.position === "start" && (<InputAdornment position="start">
                          {props.data.symbol}
                        </InputAdornment>)
                      }
                      endAdornment={
                        props.data[`ref`] === "WP" ? (
                          <>
                           {(props.data.position === "end" || props.data.isLockRequired ) ?  ( <InputAdornment position="end">
                            {props.data.position === "end" ? props.data.symbol : null } { props.data[`${props.colDef.field}_locked`] ? (<LockIcon
                                sx={{ fontSize: 15, cursor: "pointer" }}
                                onClick={() => {
                                  handleLockOption(props.data);
                                }}
                              />):(
                                <>
                                  <LockOpenIcon
                                sx={{ fontSize: 15, cursor: "pointer" }}
                                onClick={() => {
                                  handleLockOption(props.data);
                                }}
                              />
                                </>
                              )}
                            </InputAdornment>) : null }
                          </>
                        ) : (
                          <>
                          {props.data.position === "end" ? (
                                <>
                                 {props.data.symbol}
                                </>
                              ):(
                                <>
                                </>
                              )} 
                          </>
                        )
                      }
                      // disabled={(props.disabled || props.data[`${props.colDef.field}_locked`] || props.data.disabled )}
                      disabled={(props.disabled || props.data[`${props.colDef.field}_locked`] || ( props.data.disabled && props.data.specificColumns ? ( props.data.specificColumns.includes(`'${props.colDef.field}',`) ) : props.data.disabled ) )}
                      
                      className={
                        props.data[`ref`] === "WP" &&
                        props.data[`${props.colDef.field}_locked`]
                          ? classes.disabled
                          : null
                      }
                      color={getColor}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextFieldWithLabel
                      type="text"
                      onChange={handleInputChange}
                      defaultValue={( props.value || props.data[`${props.mappingKey}`] )}
                      handleOnBlur={handleOnBlur}
                      disabled={props.disabled}
                      startAdornment={
                        props.data.position === "start" && (<InputAdornment position="start">
                          {props.data.symbol}
                        </InputAdornment>)
                      }
                      endAdornment={
                        props.data.position === "end" && (<InputAdornment position="end">
                          {props.data.symbol}
                        </InputAdornment>)
                      }
                      disabled={(props.disabled || props.data[`${props.colDef.field}_locked`] || ( props.data.disabled && props.data.specificColumns ? ( props.data.specificColumns.includes(`'${props.colDef.field}',`) ) : props.data.disabled ) )}
                      className={props.disabled ? classes.textFieldDisabled : ''}
                      color={getColor}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          {props.data ? (
            <>
              {["WP", "IAF", "LY"].includes(props.data[`ref`]) ? (
                <>
                 {props.data.position === "end" ? (
                   <>
                     {props.data[`${props.mappingKey}`]} {props.data.symbol}
                   </>
                 ):(
                   <>
                    {props.data.symbol} {props.data[`${props.mappingKey}`]}
                   </>
                 )} 
                </>
              ) : (
                <>
                 {props.data.position === "end" ? (
                   <>
                     {props.data[`${props.mappingKey}`]} {props.data.symbol}
                   </>
                 ):(
                   <>
                   {props.data.symbol} {props.data[`${props.mappingKey}`]} 
                   </>
                 )}
                </>
              )}
            </>
          ) : null}
        </>
      )}
      </>
    )}
      
    </>
  );
};
