import { useMemo } from "react";
import CellGroupSet from "./CellGroupSet";
import CellDropdownMulti from "./CellDropdownMulti";
import CellTextField from "./CellTextField";
import CellCompute from "./CellCompute";
export default function CellRendererSelector(params) {
  const renderer = useMemo(() => {
      const type = params?.data && (params.data["#renderer_type"] || params?.defaultType);
    switch (type) {
      case "groupSet":
        return <CellGroupSet {...params} />;
      case "dropdown":
        return <CellDropdownMulti {...params} />;
      case "cellEdit":
        return <CellTextField {...params} />
      case "cellCompute":
        return <CellCompute {...params}/>
        default:
            return <>{params?.value}</>;
    }
  }, [params]);
  return renderer;
}
