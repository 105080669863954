/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from "moment";
import LazyLoad from "react-lazyload";
import { capitalize } from "lodash";
import { Popover } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../../pages/Home/CustomComponents/customModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
  getEventLeftSpace,
  getEventWidth,
  getEventTitleLeftSpace,
  getEventTitleWidth,
} from "../helpers";
import { eventStatusColors } from "../constants";

import styles from "../index.module.css";
import { fontSize } from "@mui/system";

function CalendarEvent({
  event,
  mapping,
  left,
  titleLeft,
  width,
  titleWidth,
  eventInfo,
  detailsAction,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [moreVertIconHover,setMoreVertIconHover]=useState(false)

  const eventStatus = event.running_status;
  const eventTimeline = `${moment(event.start_date).format(
    "DD MMM"
  )} - ${moment(event.end_date).format("DD MMM")} | `;
  const progress =
    (moment().diff(event.start_date, "days") /
      (moment(event.end_date).diff(event.start_date, "days") + 1)) *
    100;

    console.log(eventStatus,"eventStatus")

  function onEventView() {
    // history.push(`/design-experiment/${event.id}`);
  }

  function onEventAnalyze() {
    const status = event.status || "";
    const runningStatus = event.running_status || "";

    if (
      status.toLowerCase() === "finalized" &&
      runningStatus.toLowerCase() !== "planned"
    ) {
      // history.push(`/analyse-experiment/${event.id}`);
    } else {
      toast.error(
        "Please select a finalized experiment which is ongoing or completed."
      );
    }
  }

  function onEventEdit() {
    const status = event.status.toLowerCase() || "";
    const runningStatus = event.running_status.toLowerCase() || "";

    if (
      status === "draft" ||
      (status === "finalized" && runningStatus === "planned")
    ) {
      // history.push(`/design-experiment/${event.id}`);
    } else {
      toast.error("Ongoing and completed experiments are not editable.");
    }
  }

  function onEventDuplicate() {
    // dispatch({
    //  type: SET_EXPERIMENT_DETAILS_FOR_DUPLICATE,
    //  payload: { id: `ID${event.id}`, name: event.name },
    // });
  }

  function onEventDelete() {
    // dispatch({
    //  type: SET_EXPERIMENT_ID_FOR_DELETE_EXPERIMENT,
    //  payload: `ID${event.id}`,
    // });
  }

  const handleClose = () => {
    setOpen(false);
  };
  console.log(eventInfo,"eventscalender")

  return (
    <>
      {open ? (
        <>
          <CustomModal
            show={open}
            {...detailsAction}
            handleClose={handleClose}
          />
        </>
      ) : null}
      <div className={`${styles["cal-row"]}`}>
        {mapping.map(() => (
          <div
            className={`${styles["cal-cell"]}`}
            style={{ width: `${100 / mapping.length}%` }}
          />
        ))}
        <div
          className={`${styles.event}`}
          style={{
            left: `${left}%`,
            width: `${width}%`,
            // background: eventStatusColors[eventStatus].from,
            // borderColor: eventStatusColors[eventStatus].border,
            background:
              eventInfo && eventInfo.key === "tradesmart"
                ? eventInfo.labels[eventStatus].from
                : eventStatusColors[eventStatus].from,
            borderColor:
              eventInfo && eventInfo.key === "tradesmart"
                ? eventInfo.labels[eventStatus].border
                : eventStatusColors[eventStatus].border,
          }}
        >
          {eventStatus === "ongoing" && (
            <div
              className={`${styles["event-progress"]}`}
              style={{
                width: `${progress}%`,
                // background: eventStatusColors[eventStatus].border,
                background:
                  eventInfo && eventInfo.key === "tradesmart"
                    ? eventInfo.labels[eventStatus].border
                    : eventStatusColors[eventStatus].border,
              }}
            />
          )}
        </div>
        <div
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          className={`${styles["event-title-div"]}`}
          style={{
            left: `${titleLeft}%`,
            width: `${titleWidth}%`,
            cursor: "pointer",
          }}
          aria-describedby={anchorEl ? "simple-popover" : undefined}
          variant="contained"
        >
          {!(eventInfo && eventInfo.key === "tradesmart") && (
            <>
              <span
                className="event-timeline"
                // style={{ color: eventStatusColors[eventStatus].timeline }}
                style={{
                  color:
                    eventInfo && eventInfo.key === "tradesmart"
                      ? eventInfo.labels[eventStatus].timeline
                      : eventStatusColors[eventStatus].timeline,
                }}
              >
                {eventTimeline}
              </span>
             
              <span
                className={`${styles["event-title"]}`}
                // style={{ color: eventStatusColors[eventStatus].title }}
                style={{
                  
                  color:
                    eventInfo && eventInfo.key === "tradesmart"
                      ? eventInfo.labels[eventStatus].title
                      : eventStatusColors[eventStatus].title,
                }}
              >
              {event.name}
              </span>
            </>
          )}
          {eventInfo && eventInfo.key === "tradesmart" && (
            <div style={{display:"flex",justifyContent:"space-between"}}>
              {event.discount && (<div style={{  marginTop:"20px", verticalAlign:"center",marginRight:"10px"}}>
                <span  style={{verticalAlign:"center",color:"white",fontWeight:"600", padding:"8px",  background:eventInfo.labels[eventStatus].title,borderRadius:"30px"}}>
                {event.discount}
                </span>
               
                </div>)}
              
            <div
              className={`${styles["event-title"]}`}
              // style={{ color: eventStatusColors[eventStatus].title }}
              style={{
                color:
                  eventInfo && eventInfo.key === "tradesmart"
                    ? eventInfo.labels[eventStatus].title
                    : eventStatusColors[eventStatus].title,
              }}
            >
             
              <b>{event.description}</b>
              <br />
              <div>
                
              {event.name}
              </div>
             
             
              {event.days_left}
            </div></div>
          )}
          <div style={{ marginTop:"5px",display:"flex"}}>
         <div  style={{color:"grey", marginRight:"10rem"}}><InfoOutlinedIcon sx={{}}/></div> 
         {
          console.log(eventInfo.labels[eventStatus],"return-call-eventStatus")
         }
         {
          eventInfo.labels[eventStatus].moreVertIcon ? (<div style={{color:"#0055AF",border:"2px #0055AF solid",height:"30px",width:"25px",borderRadius:"12px"}} onMouseEnter={()=>{setMoreVertIconHover(true)}} onMouseLeave={()=>{setMoreVertIconHover(false)}} ><MoreVertIcon style={{MarginLeft:"-50px"}}/></div>):""
         }
         <div style={{position:"absolute",left:"15rem"}}>
         {
          moreVertIconHover ? (<div style={{padding:"5px",width:"90px",height:"30px",backgroundColor:"#0055AF", color:"white",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}}><DeleteOutlineOutlinedIcon/><CreateOutlinedIcon/><VisibilityOutlinedIcon/></div>):""
         }
         </div>
         </div>
        
         {/* <div style={{color:"grey"}}><MoreVertIcon/></div>  */}
        </div>
        
        <Popover
          id={anchorEl ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          onClick={() => setAnchorEl(null)}
        >
          <div className={`${styles["event-details-container"]}`}>
            {eventInfo && eventInfo.key === "tradesmart" ? (
              <div>
                <div className="mb-8">
                  <span className="event-timeline">From: </span>
                  <span className={`${styles["event-title"]}`}>
                    {event.start_date} to {event.end_date}
                  </span>
                </div>
                <div className="mb-8">
                  <span className="event-timeline">Status: </span>
                  <span className={`${styles["event-title"]}`}>
                    {event.status}
                  </span>
                </div>

                <div className="mb-8">
                  <span className="event-timeline">Spend: </span>
                  <span className={`${styles["event-title"]}`}>
                    {event.spend}
                  </span>
                </div>
                <div className="mb-8">
                  <span className="event-timeline">Volume: </span>
                  <span className={`${styles["event-title"]}`}>
                    {event.volume}
                  </span>
                </div>
                <div className="mb-8">
                  <span className="event-timeline">Revenue: </span>
                  <span className={`${styles["event-title"]}`}>
                    {event.revenue}
                  </span>
                </div>
                <div className="mb-8">
                  <span className="event-timeline">Margin: </span>
                  <span className={`${styles["event-title"]}`}>
                    {event.margin}
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-8">
                  <span className="event-timeline">{eventTimeline}</span>
                  <span className={`${styles["event-title"]}`}>
                    {event.type}
                  </span>
                </div>
                <div className="mb-8">
                  <span className="event-timeline">{eventTimeline}</span>
                  <span className={`${styles["event-title"]}`}>
                    {event.name}
                  </span>
                </div>
                <div className="mb-8">
                  <span className="event-timeline">{eventTimeline}</span>
                  <span className={`${styles["event-title"]}`}>
                    {event.description}
                  </span>
                </div>
              </div>
            )}
            <div className="mb-8">
              {eventInfo && eventInfo.key === "tradesmart" && (
                <Button
                  onClick={() =>
                    navigate(
                      "/" +
                        window?.location?.pathname?.split("/")[1] +
                        "/" +
                        window?.location?.pathname?.split("/")[2] +
                        "/promo-creation-finalise"
                    )
                  }
                  variant="contained"
                  style={{
                    textTransform: "none",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                >
                  Open And Edit
                </Button>
              )}
              {eventInfo && eventInfo.key === "tradesmart" && (
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                  onClick={() => setOpen(true)}
                >
                  Details
                </Button>
              )}
            </div>
            {/* <div
            className={`${styles["event-details"]}`}
            style={{ borderColor: eventStatusColors[eventStatus].border }}
          >
            <div
              className="event-status"
              style={{ color: eventStatusColors[eventStatus].border }}
            >
              {eventStatusColors[eventStatus].label}
            </div>
            <div className="flex">
              <div className="flex-column">
                <span className={`${styles["event-detail-title"]}`}>Start</span>
                <span className={`${styles["event-detail-value"]}`}>
                  {moment(event.start_date).format("DD MMM YYYY")} -{" "}
                </span>
              </div>
              <div className="flex-column">
                <span className={`${styles["event-detail-title"]}`}>End</span>
                <span className={`${styles["event-detail-value"]}`}>
                  {moment(event.end_date).format("DD MMM YYYY")}
                </span>
              </div>
            </div>
          </div> */}
            {/* <div className={`${styles["event-actions-container"]} flex`}>
            <>
              <button
                className={`${styles["event-action-btn"]} flex-center`}
                type="button"
                onClick={() => onEventView()}
              >
                <i
                  role="button"
                  style={{ color: "#3b749f" }}
                  className="fa fa-eye"
                  title="View"
                />
                <span className={`${styles["event-action-title"]}`}>View</span>
              </button>
              <button
                className={`${styles["event-action-btn"]} flex-center ml-40`}
                type="button"
                onClick={() => onEventAnalyze()}
              >
                <i
                  role="button"
                  style={{ color: "#3b749f" }}
                  className="fa fa-bar-chart"
                  title="Analyze"
                />
                <span className={`${styles["event-action-title"]}`}>
                  Analyze
                </span>
              </button>
            </>

            <button
              className={`${styles["event-action-btn"]} flex-center ml-40`}
              type="button"
              onClick={() => onEventDuplicate()}
            >
              <i
                role="button"
                style={{ color: "#3b749f" }}
                className="fa fa-clone"
                title="Duplicate"
              />
              <span className={`${styles["event-action-title"]}`}>
                Duplicate
              </span>
            </button>
            <button
              className={`${styles["event-action-btn"]} flex-center ml-40`}
              type="button"
              onClick={() => onEventDelete()}
            >
              <i
                role="button"
                className="fa fa-trash"
                title="Delete"
                style={{ color: "#3b749f" }}
              />
              <span className={`${styles["event-action-title"]}`}>Delete</span>
            </button>
          </div> */}
          </div>
        </Popover>
      </div>
    </>
  );
}

export default function CalendarEventsList({
  mapping,
  filteredEvents,
  calStDt,
  calEnDt,
  onCalEventClick,
  qtrWkMapping,
  isLoading,
  eventInfo,
  detailsAction,
}) {
  const noOfDaysInCal = calEnDt.diff(calStDt, "days") + 1;
  return (
    <div
      id="event-scroll-container"
      className={`${styles["event-list-container"]} ${
        isLoading ? styles["no-scroll"] : ""
      }`}
    >
      <div className={`${styles["event-list-container-wrapper"]}`}>
        {isLoading && <div className={`${styles.loading}`} />}
        {filteredEvents?.map((event, index) => {
          const { start_date: eventStDt, end_date: eventEnDt } = event;
          return (
            <LazyLoad
              key={index}
              scrollContainer="#event-scroll-container"
              height={67}
            >
              <CalendarEvent
                key={index}
                event={event}
                onCalEventClick={onCalEventClick}
                mapping={mapping}
                qtrWkMapping={qtrWkMapping}
                left={getEventLeftSpace({ eventStDt, calStDt, noOfDaysInCal })}
                titleLeft={getEventTitleLeftSpace({
                  eventStDt,
                  calStDt,
                  noOfDaysInCal,
                })}
                width={getEventWidth({ eventStDt, eventEnDt, noOfDaysInCal })}
                titleWidth={getEventTitleWidth({
                  eventStDt,
                  eventEnDt,
                  calStDt,
                  calEnDt,
                  noOfDaysInCal,
                })}
                eventInfo={eventInfo}
                detailsAction={detailsAction}
              />
            </LazyLoad>
          );
        })}
        {filteredEvents?.length === 0 && (
          <div className={`${styles["cal-row"]} ${styles["flex-c-a"]}`}>
            {!isLoading && "No Data"}
          </div>
        )}
      </div>
    </div>
  );
}
