import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
const CustomRadioButtons = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { apiData, dependentInfo, tableInfo, selectedRowInfo } = useSelector(
    (state) => state.home
  );
  const [radioValue, setRadioValue] = useState(data.info[0].label);

  useEffect(() => {
    if (props.data["isStoreRequired"] && props.data["defaultDependentOn"]) {
      let params = {};
      params[`${props.data.key}`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
  }, []);

  const handleRadioChange = (event, newValue) => {
    setRadioValue(newValue);
    if (props.data["isStoreRequired"]) {
      let params = {};
      params[`${props.data.key}`] = newValue === props.data.key ? true : false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
  };
  return (
    <>
      <Typography style={{ color: data.style?.fontColor || "#8596a9",fontWeight:data.style?.fontWeight || "" }}>
        {data.label}
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange}
          value={radioValue}
        >
          {data.info?.map((item) => (
            <>
              {item[`dependentOn`] ? (
                <>
                  {`New Value ${dependentInfo[`${item[`dependentOn`]}`]}`}
                  {dependentInfo[`${item[`dependentOn`]}`] === true ? (
                    <>
                      <FormControlLabel
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <FormControlLabel
                    style={item.labelStyle}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                </>
              )}
            </>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};
export default CustomRadioButtons;
