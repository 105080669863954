import React, { useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Rnd } from "react-rnd";
import CustomActionButton from "../../pages/Home/CustomComponents/CustomActionButton";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const DraggableCoreComponent = (props) => {
  const {
    widthValue,
    heightValue,
    text,
    fill,
    label,
    labelIndicator,
    labelStyle,
    x,
    y,
    fixed,
    freezeAll,
    setFreeze,
  } = props;
  const [width, setWidth] = useState(widthValue);
  const [height, setHeight] = useState(heightValue);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: fill,
  };
  return (
    <>
    {
      label ? (<h5 style={labelStyle}>
      {labelIndicator==="high" ? (<><KeyboardDoubleArrowUpIcon style={{marginBottom:"-5px"}}/>+ {label}</>):(<><KeyboardDoubleArrowDownIcon style={{marginBottom:"-5px"}}/>- {label}</>)}
      </h5>):""
    }
     {props.type === "rect" ? 
    <Rnd
      style={style}
      default={{ x: x, y: props.y + 10 }}
      size={{ width: width, height: height }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setWidth(ref.style.width);
        setHeight(ref.style.height);
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "Center",
          rotate: props.rotate || "0deg",
          border: props.border || "",
          padding:props.padding || ""
         
        }}
      >
        {/* <span onClick={setFreeze} className="lock">
          {fixed || freezeAll ? <LockIcon /> : <LockOpenIcon />}
        </span> */}
        {text && <div style={{width: "max-content", fontSize:13 , ...props?.textStyle}}>{text}</div>}
        {props.actionButton && <CustomActionButton data={props.actionButton} />}
      </div>
    </Rnd> :
    <Rnd
      style={style}
      default={{ x: x, y: props.y + 10 }}
      size={{ width: width, height: height }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setWidth(ref.style.width);
        setHeight(ref.style.height);
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "Center",
          rotate: props.rotate || "0deg",
          border: props.border || "",
          padding:props.padding || ""
         
        }}
      >
        <img src={require(`../../assets/${props.path}`)} style={{width: props.widthValue, height: props.heightValue, ...props.imageStyle}} />
      </div>
    </Rnd>
     }
    </>
  );
};

export default DraggableCoreComponent;
