import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function useAction() {
	const {
		gridRefs,
		tableInfo,
		dropdownSelectionData,
		selectedRowInfo,
		dependentInfo,
		formEditedValues,
		modalRefs,
	} = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	function onAction(action, dynamicPayload, selectedValue) {
		let actionOccurred = true;
		switch (action?.actionName) {
			case "ADD_ROWS_BY_TAG":
				{
					const src = tableInfo[action.src_table_key];
					const dest = tableInfo[action.dest_table_key];
					let rowsToAdd = null;
					const { tagInfo } = action;
					if (tagInfo?.tagSets) {
						const tagSets = {};
						Object.keys(tagInfo.tagSets).forEach(
							(key) => (tagSets[key] = new Set(tagInfo.tagSets[key]))
						);
						rowsToAdd = src.filter((row) => {
							return tagInfo.columns.every((field) =>
								tagSets[field].has(row[field])
							);
						});
					} else {
						let tags = tagInfo?.tags;
						if (tagInfo?.filters) {
							tags = tagInfo.filters.map((filterId) => {
								const options = dropdownSelectionData[filterId];
								if (options && options.length > 0) return options[0].id;
								return null;
							});
						}
						rowsToAdd = src.filter((row) => {
							return tagInfo.columns.every(
								(field, index) => row[field] === tags[index]
							);
						});
					}
					console.log(rowsToAdd);
					dispatch({
						type: "TABLE_DATA",
						payload: { [action.dest_table_key]: [...dest, ...rowsToAdd] },
					});
				}
				break;
			case "ADD_ROWS_BY_SELECTION": {
				const selectedRows = selectedRowInfo[action.source_table_key];
				if (selectedRows && selectedRows.length > 0) {
					dispatch({
						type: "TABLE_DATA",
						payload: { [action.dest_table_key]: [...selectedRows] },
					});
				}
				break;
			}
			case "DELETE_ROWS_BY_TAG":
				{
					const { tagInfo } = action;
					const tagSets = {};
					if (tagInfo?.tagSets) {
						tagInfo.columns.forEach(
							(field) => (tagSets[field] = new Set(tagInfo.tagSets[field]))
						);
					} else {
						tagInfo.columns.forEach((field) => (tagSets[field] = new Set()));
						const selectedRows = gridRefs[action.src_table_key].current.api
							.getSelectedNodes()
							?.map((node) => node.data);
						if (selectedRows && selectedRows.length > 0) {
							selectedRows.forEach((row) =>
								tagInfo.columns.forEach((field) =>
									tagSets[field].add(row[field])
								)
							);
						}
					}
					const rows = tableInfo[action.dest_table_key].filter((row) => {
						return !tagInfo.columns.every((field) =>
							tagSets[field].has(row[field])
						);
					});
					dispatch({
						type: "TABLE_DATA",
						payload: { [action.dest_table_key]: [...rows] },
					});
				}
				break;
			case "SET_ROWS_DATA": {
				const tableRows = tableInfo[action.parent_table_key];
				if (tableRows) {
					if (action?.limitToSelections) {
						if (action?.basedOnId) {
							let selectedRows = null;
							if (dynamicPayload) {
								selectedRows = dynamicPayload;
							} else if (action?.source_table_key) {
								const rows = selectedRowInfo[action.source_table_key];
								if (rows && rows.length > 0) {
									selectedRows = rows.map((row) => row["id"]);
								}
							} else {
								selectedRows = gridRefs[action.parent_table_key]?.current.api
									.getSelectedNodes()
									.map((node) => node.data["id"]);
							}
							if (selectedRows && selectedRows.length > 0) {
								const selectedIds = new Set(selectedRows);
								const rows = [...tableRows];
								rows.forEach((row) => {
									const flag = action?.invertSelection
										? !selectedIds.has(row.id)
										: selectedIds.has(row.id);
									if (flag) {
										action.data.forEach((obj) => (row[obj.field] = obj.value));
									}
								});
								dispatch({
									type: "TABLE_DATA",
									payload: { [action.parent_table_key]: [...rows] },
								});
							}
						} else {
							const selectedRows = gridRefs[
								action.parent_table_key
							]?.current.api
								.getSelectedNodes()
								.map((node) => node.rowIndex);
							if (selectedRows && selectedRows.length > 0) {
								const rows = [...tableRows];
								selectedRows.forEach((rowIndex) => {
									const row = rows[rowIndex];
									action.data.forEach((obj) => (row[obj.field] = obj.value));
								});
								dispatch({
									type: "TABLE_DATA",
									payload: { [action.parent_table_key]: [...rows] },
								});
							}
						}
					} else {
						const rows = [...tableRows];
						rows.forEach((row) =>
							action.data.forEach((obj) => (row[obj.field] = obj.value))
						);
						dispatch({
							type: "TABLE_DATA",
							payload: { [action.parent_table_key]: rows },
						});
					}
				}
				break;
			}
			case "BULK_EDIT_DROPDOWNS": {
				const selectedRows = [...selectedRowInfo[`${action.parent_table_key}`]];
				if (selectedRows && dropdownSelectionData) {
					let output = [];
					if (action?.source_dropdowns && dropdownSelectionData) {
						const dropdownsValues = action.source_dropdowns.map((value) => {
							if (Array.isArray(value)) {
								return value
									.map((id) => {
										const selectedOptions = dropdownSelectionData[id];
										return selectedOptions ? selectedOptions[0]?.value : null;
									})
									.join(",");
							} else {
								const selectedOptions = dropdownSelectionData[value];
								return selectedOptions ? selectedOptions[0]?.value : null;
							}
						});
						output = dropdownsValues;
					}
					if (action?.source_textFields && formEditedValues) {
						const textFieldsValues = action.source_textFields.map(
							(name) => formEditedValues[name]
						);
						output = output.concat(textFieldsValues);
					}
					const gridApi = gridRefs[action.parent_table_key].current.api;
					if (output && output.length > 0) {
						const selectedRowIndexes = gridApi
							.getSelectedNodes()
							.map(({ rowIndex }) => rowIndex);
						const allRows = [...tableInfo[action.parent_table_key]];
						selectedRowIndexes.forEach((index) => {
							const row = allRows[index];
							action.dest_fields.forEach((field, i) => {
								if (output[i]) row[field] = output[i];
							});
						});
						dispatch({
							type: "TABLE_DATA",
							payload: { [action.parent_table_key]: [...allRows] },
						});
					}
					if (!action?.preserveSelections) {
						gridApi.forEachNode((node) => node.setSelected(false));
					}
				}
				break;
			}
			case "click": {
				let params = {};

				params[`${action.key}`] = dependentInfo[`${action.key}`]
					? !dependentInfo[`${action.key}`]
					: true;
				if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
					params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
						? !dependentInfo[`${action.otherKey}`]
						: true;
				}
				if (action.hasOwnProperty("defaultToggle")) {
					params[`${action.key}`] = action.defaultToggle;
				}
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: params,
				});
				break;
			}
			case "RESET_TABLE": {
				const gridRef = gridRefs[action.parent_table_key].current;
				gridRef.api.forEachNode((node) => node.setSelected(false));
				dispatch({
					type: "EDIT_ACTION",
					payload: { [action.parent_table_key]: false },
				});
				break;
			}
			case "SET_KEYS": {
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: action.data,
				});
				if (action.path) {
					navigate(
						"/" +
							window?.location?.pathname?.split("/")[1] +
							"/" +
							window?.location?.pathname?.split("/")[2] +
							action.path
					);
				}
				break;
			}
			case "COPY_COLUMNS_DATA": {
				//copy columns data from one table to other table (from selected rows or whole table)
				//Both tables needs 'id' column to make it work
				const selectedRows = action?.limitToSelections
					? selectedRowInfo[action.source_table_key]
					: tableInfo[action.source_table_key];
				if (selectedRows && selectedRows.length > 0) {
					const idSet = {};
					selectedRows.forEach((row) => (idSet[row.id] = row));
					const rows = [...tableInfo[action.dest_table_key]];
					rows.forEach((row) => {
						if (idSet.hasOwnProperty(row.id)) {
							action.dest_fields.forEach(
								(field, index) =>
									(row[field] = idSet[row.id][action.source_fields[index]])
							);
						}
					});
					dispatch({
						type: "TABLE_DATA",
						payload: { [action.dest_table_key]: rows },
					});
				}
				break;
			}
			case "CLOSE_PARENT_MODAL": {
				modalRefs[action?.parent_modal_key].current.close();
				break;
			}
			case "ADD_ROWS_TO_BUFFER": {
				if (selectedRowInfo[action.selectionSource]) {
					const selectionSet = new Set(
						selectedRowInfo[action.selectionSource].map((row) => row.id)
					);
					const rows = tableInfo[action.sourceTableKey].filter((row) =>
						selectionSet.has(row.id)
					);
					dispatch({
						type: "TABLE_DATA",
						payload: { [action.destTableKey]: [...rows] },
					});
				}
				break;
			}
			case "MULTIPLY_BY_CELLS": {
				const targetTable = [...tableInfo[action.targetTableKey]];
				const sourceTable = tableInfo[action.srcTableKey];
				const rowSet = new Set(action.targetRows);
				const srcData = {};
				sourceTable.forEach((row) => {
					if (rowSet.has(row.id)) srcData[row.id] = row;
				});
				targetTable.forEach((row) => {
					if (rowSet.has(row.id)) {
						action.targetFields.forEach((field) => {
							const srcValue = parseFloat(srcData[row.id][field]);
							console.log(action.multiplier);
							if (!Number.isNaN(srcValue)) {
								row[field] = Math.trunc(
									srcValue * action.multiplier
								).toString();
							}
						});
					}
				});
				dispatch({
					type: "TABLE_DATA",
					payload: { [action.targetTableKey]: targetTable },
				});
				break;
			}

			case "UPDATE_WARNING_ICON": {
				const targetTableInfo = [...tableInfo[action.parent_table_key]];
				let targetRow = {};
				for (let i = 0; i < targetTableInfo.length; i++) {
					if (targetTableInfo[i].id === dynamicPayload[0]) {
						targetRow = { ...targetTableInfo[i] };
					}
				}
				let childRowIds = targetRow?.child_row_id;
				let childFinalisedDateMatchFlag = true;
				let childrenRows = [];
				targetTableInfo.map((row) => {
					if (childRowIds.includes(row.id)) {
						childrenRows.push(row);
					}
				});

				for (let i = 0; i < childrenRows.length; i++) {
					if (
						childrenRows[i].finalised_clearence_date !== selectedValue &&
						childrenRows[i].id !== targetRow.id
					) {
						childFinalisedDateMatchFlag = false;
					}
				}

				if (childFinalisedDateMatchFlag) {
					targetTableInfo.map((row, index) => {
						if (childRowIds.includes(row["id"])) {
							row["#add_icon_parent_style_id"] = "";
							row["clearence_indicator"] = "LOW,green";
							targetTableInfo[index] = row;
						}
					});
				}
				if (!childFinalisedDateMatchFlag) {
					targetTableInfo.map((row, index) => {
						if (childRowIds.includes(row["id"])) {
							row["#add_icon_parent_style_id"] = "Y";
							row["clearence_indicator"] = "URGENT,red";
							targetTableInfo[index] = row;
						}
					});
				}

				dispatch({
					type: "TABLE_DATA",
					payload: { [action.parent_table_key]: targetTableInfo },
				});
			}
			default:
				actionOccurred = false;
		}
		return actionOccurred;
	}
	function invokeAction(action, dynamicPayload = null, selectedValue) {
		if (action && Array.isArray(action)) {
			action.forEach((obj) => onAction(obj, dynamicPayload));
		} else {
			onAction(action, dynamicPayload, selectedValue);
		}
	}
	return invokeAction;
}
