import { useCallback, useEffect, useState } from "react";
import ComponentResolver from "../Resolver/ComponentResolver";
import { useSelector } from "react-redux";
const Container = (props) => {
  const { data } = props;
  const [childComponentsList, setChildComponentsList] = useState([]);
  const { filtersDependentInfo } = useSelector((state) => state.home);
  useEffect(() => {
    setChildComponentsList(data?.components || []);
  }, []);

  const renderContainer = useCallback(
    (comp) => {
      if (comp?.filtersDependentOn) {
        const [id, category, option] = data.filtersDependentOn;
        if (Object.keys(filtersDependentInfo).length > 0) {
          if (filtersDependentInfo[id].hasOwnProperty(category)) {
            console.log(filtersDependentInfo[id][category])
            if (filtersDependentInfo[id][category].size === 0) {
              return option === null;
            } else {
              return filtersDependentInfo[id][category].has(option);
            }
          }
          return false
        }
        return false;
      }
      return true;
    },
    [filtersDependentInfo]
  );

  return renderContainer(data, filtersDependentInfo) ? (
    <div
      style={{
        ...data.style,
        borderRadius: 3,
      }}
    >
      <ComponentResolver
        pageObject={{ components: data.components }}
        spread={true}
      />
    </div>
  ) : null;
};

export default Container;
