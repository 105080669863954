import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Apps from "@mui/icons-material/Apps";

export default function CustomToggle(props) {
  const { data } = props;
  const [state, setState] = useState(false);
  const dispatch = useDispatch();
  function handleToggle(value) {
    setState(value);
  }

  useEffect(() => {
    const params = { [data.key]: state };
    dispatch({
      type: "DEPENDENT_COMPONENTS",
      payload: params,
    });
  }, [state]);

  const renderToggleView=(obj)=>{
    switch (obj.type) {
      case "Apps":
        return ( <Apps
                onClick={() => {
                  handleToggle(true)
                  if(obj.hasOwnProperty("action")){
                    let params = {}
                    params[`${obj.action.key}`] = true
                    params[`${obj.action.otherKey}`] = false
                    dispatch({
                      type: "DEPENDENT_COMPONENTS",
                      payload: params,
                    });
                  }
                }}
                sx={{ color: (state ? "blue" : "grey" ), cursor:"pointer" }}
              />)
        break;
      case "FormatListBulletedIcon":
        return (
          <FormatListBulletedIcon
        onClick={() => { 
          handleToggle(false)
                  if(obj.hasOwnProperty("action")){
                    let params = {}
                    params[`${obj.action.key}`] = true
                    params[`${obj.action.otherKey}`] = false
                    dispatch({
                      type: "DEPENDENT_COMPONENTS",
                      payload: params,
                    });
            }
        }}
        sx={{ color: ( state ? "grey" : "blue"), cursor:"pointer" }}
      />
        )
      default:
        break;
    }
    
  }
  return (
    <Grid>
      <div
        style={{
          border: "1px solid grey",
          display: "flex",
          justifyContent: "center",
          width: "fit-content",
          borderRadius: 2,
        }}
      >
        {
          data['toggleOptions'] ? (
            <>
              {data['toggleOptions'].map(obj=>(
                renderToggleView(obj)
              ))}
            </>
          ):(
            <>
              <Apps
                onClick={() => handleToggle(true)}
                sx={{ color: (state ? "blue" : "grey" ), cursor:"pointer" }}
              />
              <FormatListBulletedIcon
                onClick={() => handleToggle(false)}
                sx={{ color: ( state ? "grey" : "blue"), cursor:"pointer" }}
              />
                  </>
                )
        }
        
      </div>
    </Grid>
  );
}
