import { useRef, useState } from "react";
import { Box } from "@mui/system";
import axios from "axios";
import { Button, Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const useStyles = makeStyles({
  parentStyle: {
    width: "35%",
    boxShadow: "0px 3px 10px #0000000F",
  },
  optionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5%",
    background: "#EDF1F4",
    padding: "0rem 1.5rem 1rem",
    borderRadius: "8px",
  },
  iconStyle: {
    fontSize: "3rem",
    color: "#9EAABD",
    marginTop: "10px",
  },
  infoContainer: {
    flexGrow: 1,
  },
  availableOptionStyle: {
    fontSize: 12,
    fontWeight: 600,
    color: "#9FA4A7",
  },
  nameStyle: {
    fontWeight: 600,
    color: "#3F4548",
  },
  buttonStyle: {
    background: "#FDFDFD",
    color: "#7D7D7F",
    borderColor: "#DEDFE0",
    fontWeight: 500,
    marginTop: "10px",
  },
  fileInputStyle: {
    height: "1px",
    width: "1px",
    opacity: 0,
    margin: 0,
    padding: 0,
  },
  closeIconStyle:{
    color: "#9EAABD",
    fontSize: 20,
    cursor: "pointer"
  }
});
const FileSelectors = (props) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const FileInputRef = useRef(null);
  const activateBrowseFile = () => {
    FileInputRef.current.click();
  };
  const clearSelection = () => {
    setSelectedFile(null);
  };
  return (
    <>
      <Box className={classes.parentStyle}>
        <Box className={classes.optionsContainer}>
          {selectedFile === null ? (
            <BackupOutlinedIcon className={classes.iconStyle} />
          ) : (
            <CloudDoneOutlinedIcon className={classes.iconStyle} />
          )}
          <Box
            className={classes.infoContainer}
            onDrop={(e) => {
              e.preventDefault();
              setSelectedFile(e.dataTransfer.files[0]);
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              className={classes.fileInputStyle}
              type="file"
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ref={FileInputRef}
              multiple={false}
              onChange={(e) => {
                e.preventDefault();
                setSelectedFile(e.target.files[0]);
              }}
            />
            <Typography
              className={classes.nameStyle}
              onClick={activateBrowseFile}
            >
              Drag and drop file here
            </Typography>
            <Typography className={classes.availableOptionStyle}>
              File type: DOCX, DOC, PDF
            </Typography>
          </Box>
          <Button
            variant="outlined"
            size="small"
            className={classes.buttonStyle}
            onClick={activateBrowseFile}
          >
            Browse File
          </Button>
        </Box>
        {selectedFile === null ? (
          ""
        ) : (
          <Box className={classes.optionsContainer} sx={{background: "none!important"}}>
            <InsertDriveFileOutlinedIcon className={classes.iconStyle} />
            <Typography className={classes.nameStyle} sx={{flexGrow: 1}}>
              {selectedFile.name}
            </Typography>
            <CloseOutlinedIcon className={classes.closeIconStyle} onClick={clearSelection}/>
          </Box>
        )}
      </Box>
    </>
  );
};
export default FileSelectors;
