import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Table } from "../../../components/Table";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";

const SplitTableLululemon = ({ data }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState([{ label: "B1", value: "B1" }]);
  const [options, setOptions] = useState([
    { label: "A1", value: "A1" },
    { label: "A2", value: "A2" },
    { label: "A3", value: "A3" },
    { label: "B1", value: "B1" },
    { label: "B2", value: "B2" },
    { label: "B3", value: "B3" },
  ]);
  const {
    apiData,
    dependentInfo,
    tableInfo,
    selectedRowInfo,
    formEditedValues,
  } = useSelector((state) => state.home);

  useEffect(() => {
    if (formEditedValues[`split-table`]) {
      let reqParmas = {};
      const gradeCluster = tableInfo[`tb_grade_cluster_breakdown`];
      reqParmas[`${data["addTable"].key}`] = gradeCluster.filter(
        (obj) => obj.cluster === formEditedValues[`split-table`]
      );
      reqParmas[data["removeTable"].key] = gradeCluster.filter(
        (obj) => obj.cluster === value[0].label
      );
      console.log("REquest params", reqParmas);
      dispatch({
        type: "TABLE_DATA",
        payload: reqParmas,
      });
    }
  }, []);

  const [leftTableLength, setleft] = useState(data.allItems);
  const [rightTableLength, setRight] = useState(data.currentItems);

  const addToRemove = () => {
    // tb_item_group_allitem to tb_item_group_management
    const storeList = tableInfo[`${data["removeTable"].key}`] || [];
    const selectedData = selectedRowInfo[`${data["addTable"].key}`];
    const storeIds = selectedData.map((obj) => obj.store_code);
    const store_list_next = tableInfo[`${data["addTable"].key}`].filter(
      (obj) => storeIds.indexOf(obj.store_code) === -1
    );

    let reqParmas = {};
    reqParmas[`${data["removeTable"].key}`] = [...storeList, ...selectedData];
    let lenLeft = [...storeList, ...selectedData];
    setleft(lenLeft.length);

    reqParmas[data["addTable"].key] = [...store_list_next];
    let lenRight = [...store_list_next];
    setRight(lenRight.length);

    let selectedRowParams = {};
    selectedRowParams[`${data["removeTable"].key}`] = [];
    selectedRowParams[data["addTable"].key] = [];
    dispatch({
      type: "TABLE_DATA",
      payload: reqParmas,
    });
    dispatch({
      type: "SELECTED_ROWS",
      payload: selectedRowParams,
    });
  };
  const removeToAdd = () => {
    // tb_item_group_management to tb_item_group_allitem
    const store_list_next = tableInfo[`${data["addTable"].key}`] || [];
    const selectedData = selectedRowInfo[`${data["removeTable"].key}`];
    const storeIds = selectedData.map((obj) => obj.store_code);
    const store_list = tableInfo[`${data["removeTable"].key}`].filter(
      (obj) => storeIds.indexOf(obj.store_code) === -1
    );
    let reqParmas = {};
    reqParmas[`${data["addTable"].key}`] = [
      ...store_list_next,
      ...selectedData,
    ];
    let lenRight = [...store_list_next, ...selectedData];
    setRight(lenRight.length);

    reqParmas[data["removeTable"].key] = [...store_list];
    let lenLeft = [...store_list];
    setleft(lenLeft.length);

    let selectedRowParams = {};
    selectedRowParams[`${data["removeTable"].key}`] = [];
    selectedRowParams[data["addTable"].key] = [];
    dispatch({
      type: "TABLE_DATA",
      payload: reqParmas,
    });
    dispatch({
      type: "SELECTED_ROWS",
      payload: selectedRowParams,
    });
  };
  return (
    <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
      <>
        <Grid item xs={4.7}>
          <Table
            apikey={data["addTable"].key}
            isStoreRequired={true}
            isApiNotRequird={false}
            isClickBasedOnParent={true}
            tableCssStyle={"sizeColumnsToFit"}
            columnDefs={data["addTable"].info[0].column}
            floatingFilter={false}
            // height={"450px"}
            rowSelection={"multiple"}
          />
        </Grid>
        <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1.3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                style={{ textTransform: "none", margin: 5 }}
                variant={"outlined"}
                size={""}
                onClick={addToRemove}
              >
                {"Secondary>>"}
              </Button>
              <Button
                style={{ textTransform: "none", margin: 5 }}
                variant={"outlined"}
                size={""}
                onClick={removeToAdd}
              >
                {"<< Primary"}
              </Button>
            </div>
          </Grid>
        <Grid item xs={4.7}>
          <Table
            apikey={data["removeTable"].key}
            isStoreRequired={true}
            isClickBasedOnParent={true}
            isApiNotRequird={false}
            tableCssStyle={"sizeColumnsToFit"}
            columnDefs={data["removeTable"].info[0].column}
            floatingFilter={false}
            // height={"450px"}
            rowSelection={"multiple"}
          />
        </Grid>
        <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1.3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                style={{ textTransform: "none", margin: 5 }}
                variant={"outlined"}
                size={""}
                onClick={addToRemove}
              >
                {"Tertiary >>"}
              </Button>
              <Button
                style={{ textTransform: "none", margin: 5 }}
                variant={"outlined"}
                size={""}
                onClick={removeToAdd}
              >
                {"<<Secondary"}
              </Button>
            </div>
          </Grid>
      </>
    </Grid>
  );
};
export default SplitTableLululemon;
