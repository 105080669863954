import React, { Component } from "react";
// import PropTypes from 'prop-types';
import moment from "moment";
import { calendarConfig } from "./config";
import CalendarHeader from "./Components/CalendarHeader";
import CalendarNavigation from "./Components/CalendarNavigation";
import CalendarViewToggle from "./Components/CalendarViewToggle";
import CalendarEventsList from "./Components/CalendarEventsList";
import CalendarEventsColorInfo from "./Components/CalendarEventsColorInfo";
import { toggleOptions, headeCellView } from "./constants";
import {
  genMoWkMapping,
  genQtrWkMapping,
  getCurrQtrFrmWK,
  getCurrMoFrmWK,
  getWksCnt,
  getDtsFrmWK,
  getWkStDt,
  getWkEdDt,
  filterEventsforDisplay,
} from "./helpers";
import { getMultipleModelsData } from "../../pages/Home/action";

import styles from "./index.module.css";

const { fstMoOfYr } = calendarConfig;

class Calendar extends Component {
  constructor(props) {
    super(props);
    const currYr = moment().year();
    const currWk = moment().week();
    const currMo = moment().month() - fstMoOfYr;

    const { noOfWks, moWkMapping, qtrWkMapping } = this.generateMapping(currYr);
    const currQtr = getCurrQtrFrmWK(qtrWkMapping, currWk);

    this.state = {
      calTypToggleVal: "quarter",
      currMo,
      noOfWks,
      moWkMapping,
      qtrWkMapping,
      calViewState: {
        wk: currWk,
        mo: currMo,
        qtr: currQtr,
        yr: currYr,
      },
      eventList: [],
      isLoading: false,
      isApiExecuted: false,
    };
  }

  componentDidMount() {
    const {
      dataObj: { key },
    } = this.props;
    const { isApiExecuted } = this.state;
    this.setState({
      isLoading: true,
    });
    if (key && !isApiExecuted) {
      getMultipleModelsData({ model_names: [key] }).then((res) => {
        console.log("res_cal", res);
        console.log("res_cal_key", res[key]);
        this.setState({
          eventList: res[key],
          isLoading: false,
          isApiExecuted: true,
        });
      });
    }
  }

  generateMapping = (yr) => {
    const noOfWks = getWksCnt(yr);
    const moWkMapping = genMoWkMapping(fstMoOfYr, noOfWks);
    const qtrWkMapping = genQtrWkMapping(noOfWks);
    return { noOfWks, moWkMapping, qtrWkMapping };
  };

  onToggleChange = (val) => {
    this.setState({
      calTypToggleVal: val,
    });
  };

  onPrevClick = () => {
    const { calViewState, moWkMapping, calTypToggleVal, qtrWkMapping } =
      this.state;
    switch (calTypToggleVal) {
      case "week":
        if (calViewState.wk === 1) {
          this.onPrevYrChng();
          return;
        }
        const prevWk = calViewState.wk - 1;
        this.setState({
          calViewState: {
            ...calViewState,
            wk: prevWk,
            mo: getCurrMoFrmWK(moWkMapping, prevWk),
            qtr: getCurrQtrFrmWK(qtrWkMapping, prevWk),
          },
        });
        break;
      case "month":
        if (calViewState.mo === 0) {
          this.onPrevYrChng();
          return;
        }
        const prevMo = calViewState.mo - 1;
        const wkRespectivePrevMo = moWkMapping[prevMo][0];
        this.setState({
          calViewState: {
            ...calViewState,
            wk: wkRespectivePrevMo,
            mo: prevMo,
            qtr: getCurrQtrFrmWK(qtrWkMapping, wkRespectivePrevMo),
          },
        });
        break;
      case "quarter":
        if (calViewState.qtr === 0) {
          this.onPrevYrChng();
          return;
        }
        const prevQtr = calViewState.qtr - 1;
        const wkRespectivePrevqtr = qtrWkMapping[prevQtr][0];
        this.setState({
          calViewState: {
            ...calViewState,
            wk: wkRespectivePrevqtr,
            mo: getCurrMoFrmWK(moWkMapping, wkRespectivePrevqtr),
            qtr: prevQtr,
          },
        });
        break;
      case "year":
        this.onPrevYrChng();
        break;

      default:
        break;
    }
  };

  onNxtClick = () => {
    const {
      calViewState,
      moWkMapping,
      calTypToggleVal,
      qtrWkMapping,
      noOfWks,
    } = this.state;
    switch (calTypToggleVal) {
      case "week":
        if (calViewState.wk === noOfWks) {
          this.onNxtYrChng();
          return;
        }
        const nxtWk = calViewState.wk + 1;
        this.setState({
          calViewState: {
            ...calViewState,
            wk: nxtWk,
            mo: getCurrMoFrmWK(moWkMapping, nxtWk),
            qtr: getCurrQtrFrmWK(qtrWkMapping, nxtWk),
          },
        });
        break;
      case "month":
        if (calViewState.mo === 11) {
          this.onNxtYrChng();
          return;
        }
        const nxtMo = calViewState.mo + 1;
        const wkRespectiveNxtMo = moWkMapping[nxtMo][0];
        this.setState({
          calViewState: {
            ...calViewState,
            wk: wkRespectiveNxtMo,
            mo: nxtMo,
            qtr: getCurrQtrFrmWK(qtrWkMapping, wkRespectiveNxtMo),
          },
        });
        break;
      case "quarter":
        if (calViewState.qtr === 3) {
          this.onNxtYrChng();
          return;
        }
        const nxtQtr = calViewState.qtr + 1;
        const wkRespectiveNxtqtr = qtrWkMapping[nxtQtr][0];
        this.setState({
          calViewState: {
            ...calViewState,
            wk: wkRespectiveNxtqtr,
            mo: getCurrMoFrmWK(moWkMapping, wkRespectiveNxtqtr),
            qtr: nxtQtr,
          },
        });
        break;
      case "year":
        this.onNxtYrChng();
        break;

      default:
        break;
    }
  };

  onYrChng = (yr, calViewState) => {
    this.setState({
      calViewState,
      ...this.generateMapping(yr),
    });
  };

  onNxtYrChng = () => {
    const nxtYr = this.state.calViewState.yr + 1;
    const calViewState = {
      mo: 0,
      wk: 1,
      qtr: 0,
      yr: nxtYr,
    };
    this.onYrChng(nxtYr, calViewState);
  };

  onPrevYrChng = () => {
    const prevYr = this.state.calViewState.yr - 1;
    const calViewState = {
      mo: 11,
      wk: getWksCnt(prevYr),
      qtr: 3,
      yr: prevYr,
    };
    this.onYrChng(prevYr, calViewState);
  };

  getNavigationText = () => {
    const { calTypToggleVal, calViewState } = this.state;

    switch (calTypToggleVal) {
      case "week":
        return `Week ${calViewState.wk} - FY ${calViewState.yr}`;
      case "month":
        return `${moment()
          .month(calViewState.mo + fstMoOfYr)
          .format("MMM")} - FY ${calViewState.yr}`;
      case "quarter":
        return `Quarter ${calViewState.qtr + 1} - FY ${calViewState.yr}`;
      case "year":
        return `FY Year ${calViewState.yr}`;
      default:
        break;
    }
  };

  getCalDts = () => {
    const { calTypToggleVal, calViewState, moWkMapping, qtrWkMapping } =
      this.state;
    const { wk, mo, qtr, yr } = calViewState;
    let stDt;
    let enDt;
    switch (calTypToggleVal) {
      case "week":
        stDt = getWkStDt(yr, wk);
        enDt = getWkEdDt(yr, wk);
        break;
      case "month":
        stDt = getWkStDt(yr, moWkMapping[mo][0]);
        enDt = getWkEdDt(yr, moWkMapping[mo][moWkMapping[mo].length - 1]);
        break;
      case "quarter":
        stDt = getWkStDt(yr, qtrWkMapping[qtr][0]);
        enDt = getWkEdDt(yr, qtrWkMapping[qtr][qtrWkMapping[qtr].length - 1]);
        break;
      case "year":
        stDt = getWkStDt(yr, moWkMapping[0][0]);
        enDt = getWkEdDt(yr, moWkMapping[11][moWkMapping[11].length - 1]);
        break;
      default:
        break;
    }
    return { stDt, enDt };
  };

  onHeaderClick = (idx) => {
    const { calTypToggleVal, calViewState } = this.state;
    switch (headeCellView[calTypToggleVal]) {
      case "month":
        this.setState({
          calTypToggleVal: "month",
          calViewState: {
            ...calViewState,
            mo: idx,
          },
        });
        break;
      case "week":
        this.setState({
          calTypToggleVal: "week",
          calViewState: {
            ...calViewState,
            wk: idx,
          },
        });
        break;

      default:
        break;
    }
  };

  render() {
    const {
      moWkMapping,
      qtrWkMapping,
      calTypToggleVal,
      calViewState,
      isLoading,
    } = this.state;
    const { onCalEventClick } = this.props;
    const { eventList } = this.state;
    const { stDt: calStDt, enDt: calEnDt } = this.getCalDts();
    const mapping = {
      year: moWkMapping,
      quarter: qtrWkMapping[calViewState.qtr],
      month: moWkMapping[calViewState.mo],
      week: getDtsFrmWK(calViewState.yr, calViewState.wk),
    };

    const filteredEvents = filterEventsforDisplay({
      eventList,
      calStDt,
      calEnDt,
    });

    return (
      <div style={{ position: "relative" }}>
        <CalendarViewToggle
          calTypToggleVal={calTypToggleVal}
          onToggleChange={this.onToggleChange}
          toggleOptions={toggleOptions}
        />
        <CalendarNavigation
          onPrevClick={this.onPrevClick}
          onNxtClick={this.onNxtClick}
          navigationText={this.getNavigationText}
        />
        <div className={`${styles["calendar-container"]}`}>
          <CalendarHeader
            yr={calViewState.yr}
            filteredEventsCount={filteredEvents?.length}
            mapping={mapping[calTypToggleVal]}
            onHeaderClick={this.onHeaderClick}
            fstMoOfYr={fstMoOfYr}
            calTypToggleVal={calTypToggleVal}
            headerViewType={headeCellView[calTypToggleVal]}
          />
          <div className={`${styles["calendar-events-loading-wrapper"]}`}>
            {isLoading && (
              <div
                className={`${styles["loading-text"]} ${styles["cal-row"]} ${styles["flex-c-a"]}`}
              >
                Loading...
              </div>
            )}
            <CalendarEventsList
              filteredEvents={filteredEvents}
              calStDt={calStDt}
              calEnDt={calEnDt}
              onCalEventClick={onCalEventClick}
              qtrWkMapping={qtrWkMapping}
              mapping={mapping[calTypToggleVal]}
              isLoading={isLoading}
              eventInfo={this.props.dataObj.eventInfo}
              detailsAction={this.props.dataObj.detailsAction}
            />
          </div>
          <CalendarEventsColorInfo eventInfo={this.props.dataObj.eventInfo} />
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  // fstMoOfYr: PropTypes.oneOf([0, 1, 2, 3, 4]),
};

export default Calendar;
