import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import ActionComponents from "./actionComponents";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import HistoryIcon from '@mui/icons-material/History';
import moment from "moment";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LockIcon from "@mui/icons-material/Lock";
import CustomDatePicker from "../../../components/CustomDatePicker";
import TimePicker from "react-time-picker";
import { CustomModal } from "./customModal";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Download from "@mui/icons-material/Download";
import Delete from "@mui/icons-material/Delete";
import DeleteIcon from '@mui/icons-material/Delete';
import ReactMuiDatePicker from "./ReactMuiDatePicker";
import { ChevronRight } from "@mui/icons-material";
export const CustomRendererComponent = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { tableInfo, editActionInfo ,selectedRowInfo ,tableValues , formEditedValues , dependentInfo} = useSelector((state) => state.home);
	const { formMasterValuesData } = useSelector((state) => state.filter);
	const [updatedValue, setUpdatedValue] = useState({});
	const [open, setOpen] = useState(false);
	const updateState = (params) => {
		console.log(params);
	};
	const handleDateRangeChange = (values) => {
		// let params = {};
		// params[`${filterObj.label}`] = values;
		// this.updateState(params);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const renderTableComponents = () => {
		let value = "";
		if (props.type === "valueFormatter") {
			props.keys.forEach((obj, index) => {
				if (index === props.keys.length - 1) {
					value = value + props.data[`${obj}`];
				} else {
					value = value + props.data[`${obj}`] + (props.separator || " ");
				}
			});
		}
		const handleTextFieldChange = (e) => {
			const { name, value } = e.target;
		};
		const handleInputChange = (e) => {
			const { label, value } = e.target;
			const updateObj = {
				currentRow: props.data,
				field: props.colDef.field,
				value: value,
				rowData: props.rowData,
				rowIndex: parseInt(props.data.id) - 1,
			};
			setUpdatedValue(updateObj);
		};
		const handleOnBlur = () => {
			// props.handleCellChange(updatedValue);
			const { field, value, currentRow, rowIndex } = updatedValue;
			if (Object.keys(updatedValue).length > 0) {
				let params = {};
				if (props.action && props.action.type) {
					switch (props.action.type) {
						case "ADD_TO_TABLE":
							const updatedData = tableInfo[`${props.parent_table_key}`].filter(
								(obj) => {
									if (obj.id === currentRow.id) {
										obj[`${props.colDef.field}`] = value;
										return obj;
									}
									return obj;
								},
							);
							params[`${props.parent_table_key}`] = [...updatedData];
							dispatch({
								type: "TABLE_DATA",
								payload: params,
							});

							break;
						case "VARIANCE_DIFF":
							const columns = props.colDef.field.split("_");
							let quarterName = columns[0];
							const diff =
								parseInt(value.replace(/\,/g, "")) -
								parseInt(
									currentRow[`${quarterName}_mfp_original_plan`].replace(
										/\,/g,
										"",
									),
								);
							const varianceDiff = tableInfo[
								`${props.parent_table_key}`
							].filter((obj) => {
								if (obj.id === currentRow.id) {
									obj[`${props.colDef.field}`] = value;
									obj[`${quarterName}_var`] = diff;
									return obj;
								}
								return obj;
							});
							params[`${props.parent_table_key}`] = [...varianceDiff];
							dispatch({
								type: "TABLE_DATA",
								payload: params,
							});
							break;
						case "MONTHLY_VARIANCE_DIFF":
							const monthlyColumns = props.colDef.field.split("_");
							let monthlyQuarterName = monthlyColumns[0];
							const varianceMonthDiff =
								parseInt(value.replace(/\,/g, "")) -
								parseInt(
									currentRow[`${monthlyQuarterName}_mfp_original_plan`].replace(
										/\,/g,
										"",
									),
								);
							const varianceDiffData = tableInfo[
								`${props.parent_table_key}`
							].filter((obj) => {
								if (obj.id === currentRow.id) {
									obj[`${props.colDef.field}`] = value;
									obj[`${monthlyQuarterName}_var`] = varianceMonthDiff;
									return obj;
								}
								return obj;
							});
							params[`${props.parent_table_key}`] = [...varianceDiffData];
							dispatch({
								type: "TABLE_DATA",
								payload: params,
							});
							break;
						case "CALCLUATE_ST_PERCENTAGE":
							if(tableInfo[`${props.parent_table_key}_copy`]){
								const updatedArray = tableInfo[`${props.parent_table_key}_copy`].filter((obj) => {
									if (obj[`${props.uniqueKey}`] == currentRow[`${props.uniqueKey}`]) {
										obj[`${props.colDef.field}`] = value;
										let totalSum = 0
										props.action.valuesUpdateOn.forEach(updatedObj=>{
											obj[`${updatedObj.colName}`] = Math.round( ( value * updatedObj.percentage )/100);
											totalSum = totalSum + obj[`${updatedObj.colName}`]
										})
										obj[`${props.action.substractedKey}`] = totalSum	
										obj[`${props.action.assignedKey}`] = value - totalSum
										return obj;
									}
									return obj;
								});
								params[`${props.parent_table_key}`] = [...updatedArray];
								dispatch({
									type: "TABLE_VALUES",
									payload: params,
								});
							}
							 
								break;
						case "TABLE_EDITED_VALUES":
						if(props.data && props.data[`${props.conditionKey}`] === 'Min'){
							let tableParams = {}
							const updatedData = 	tableInfo[`${props.parent_table_key}`].filter(obj=> {
								if( obj.name === "Allocate Quantity" ){
									obj[`${props.colDef.field}`] =  parseFloat(obj[`${props.colDef.field}`]) + parseFloat(value)
									params[`${props.colDef.field}_allocated_qty`] = obj[`${props.colDef.field}`]
								}else if (obj.name === "Min"){
									obj[`${props.colDef.field}`] =  parseFloat(value)
								}
								return obj;
							})
							tableParams[`${props.parent_table_key}`] = [...updatedData]
							params[`${props.data[`${props.conditionKey}`]}_${props.colDef.field}`] = value
							dispatch({
								type: "TABLE_DATA",
								payload: tableParams,
							});
						}else if(props.data && props.data[`${props.conditionKey}`] === 'Allocate Quantity'){
							let tableParams = {}
							const updatedData = 	tableInfo[`${props.parent_table_key}`].filter((obj,index)=> {
								if( obj.name === "Min" ){
									obj[`${props.colDef.field}`] = parseFloat(value) -  parseFloat(tableInfo[`${props.parent_table_key}`][index + 1][`${props.colDef.field}`])
								}else if (obj.name === "Allocate Quantity"){
									obj[`${props.colDef.field}`] =  parseFloat(value)
									params[`${props.colDef.field}_allocated_qty`] = obj[`${props.colDef.field}`]
								}
								return obj;
							})
							tableParams[`${props.parent_table_key}`] = [...updatedData]
							params[`${props.data[`${props.conditionKey}`]}_${props.colDef.field}`] = value
							dispatch({
								type: "TABLE_DATA",
								payload: tableParams,
							});
						} else{
							if(props.data[`${props.conditionKey}`] === 'Packs per store' && formEditedValues[`Eaches per store_${props.colDef.field}`]){
								let packs_value = 12 * value + 6 * formEditedValues[`Eaches per store_${props.colDef.field}`]
								let tableParams = {}
								const updatedData = tableInfo[`${props.parent_table_key}`].filter((obj,index)=> {
									if( obj.name === "Min" ){
										obj[`${props.colDef.field}`] = parseFloat(packs_value) -  parseFloat(tableInfo[`${props.parent_table_key}`][index + 1][`${props.colDef.field}`])
									}else if (obj.name === "Allocate Quantity"){
										obj[`${props.colDef.field}`] =  packs_value
										params[`${props.colDef.field}_allocated_qty`] = obj[`${props.colDef.field}`]
									}else if (obj.name === 'Packs per store'){
										obj[`${props.colDef.field}`] =  value
									}else if (obj.name === 'Eaches per store'){
										obj[`${props.colDef.field}`] =  formEditedValues[`Eaches per store_${props.colDef.field}`]
									}
									return obj;
								})
								tableParams[`${props.parent_table_key}`] = [...updatedData]
								params[`${props.data[`${props.conditionKey}`]}_${props.colDef.field}`] = value
								dispatch({
									type: "TABLE_DATA",
									payload: tableParams,
								});
							} else if (props.data[`${props.conditionKey}`] === 'Eaches per store' && formEditedValues[`Packs per store_${props.colDef.field}`]) {
								let packs_value = 12 * formEditedValues[`Packs per store_${props.colDef.field}`] + 6 * value
								let tableParams = {}
								const updatedData = tableInfo[`${props.parent_table_key}`].filter((obj,index)=> {
									if( obj.name === "Min" ){
										obj[`${props.colDef.field}`] = parseFloat(packs_value) -  parseFloat(tableInfo[`${props.parent_table_key}`][index + 1][`${props.colDef.field}`])
									}else if (obj.name === "Allocate Quantity"){
										obj[`${props.colDef.field}`] =  packs_value
										params[`${props.colDef.field}_allocated_qty`] = obj[`${props.colDef.field}`]
									} else if (obj.name === 'Eaches per store'){
										obj[`${props.colDef.field}`] =  value
									}else if (obj.name === 'Packs per store'){
										obj[`${props.colDef.field}`] =  formEditedValues[`Packs per store_${props.colDef.field}`]
									}
									return obj;
								})
								tableParams[`${props.parent_table_key}`] = [...updatedData]
								params[`${props.data[`${props.conditionKey}`]}_${props.colDef.field}`] = value
								dispatch({
									type: "TABLE_DATA",
									payload: tableParams,
								});
							}
							params[`${props.data[`${props.conditionKey}`]}_${props.colDef.field}`] = value
						}	
						
						dispatch({
							type: "FORM_EDITED_VALUES",
							payload: params,
						});

						break;
						default:
							break;
					}
				}
			}
		};
		const handleLockOption = (rowData) => {
			let params = {};
			// const metricData = tableInfo["tb_plan_department_locked_celldata"]
			let metricData = tableInfo[`${props.action.parent_table_key}`];
			switch (props.action.type) {
				case "ROW_LOCKING":
					metricData = metricData.filter((obj) => {
						if (obj.ref === rowData.ref) {
							obj[`${props.colDef.field}_locked`] = obj[
								`${props.colDef.field}_locked`
							]
								? false
								: true;
							return obj;
						}
						return obj;
					});
					params[`${props.action.parent_table_key}`] = metricData;
					dispatch({
						type: "TABLE_DATA",
						payload: params,
					});
					break;

				default:
					break;
			}
		};

		const onAction = (action) => {
			console.log("Action Name",action)
			if (action.actionName === "modal") {
				setOpen(!open);
			} else if (action.actionName === "PAGE_OPEN_DEPENDENT_ON_KEYS") {
				let params = {};
					if(action.dependentOnClick){
						params[`${action.dependentOnClick}`] = true
					}
					if(action.default_table_data){
						params[`${action.default_table_data}`] = action.default_table_data
					}
					if(action.path){
						navigate("/" +
						window?.location?.pathname?.split("/")[1] +
						"/" +
						window?.location?.pathname?.split("/")[2] +
						action.path);
					}
					
			} else if (action.actionName === "navigate") {
				if(action.path){
					navigate("/" +
					window?.location?.pathname?.split("/")[1] +
					"/" +
					window?.location?.pathname?.split("/")[2] +
					action.path);
				}
			} else if (action.actionName === "DEPENDENT_KEY") {
				let params = {};
		  
				params[`${action.key}`] = dependentInfo[`${action.key}`]
				  ? !dependentInfo[`${action.key}`]
				  : true;
				if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
				  params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
					? !dependentInfo[`${action.otherKey}`]
					: true;
				} else {
				  // if(action.otherKey){
				  params[`${action.otherKey}`] = true;
				  // }
				}

				dispatch({
				  type: "DEPENDENT_COMPONENTS",
				  payload: params,
				});
				  
			} else {
				return null;
			}
		};

		const displayIcons = (obj) => {
			switch (obj.type) {
				case "Forward":
					// return <EditIcon />
					return (
						<Button
							variant="outlined"
							aria-label="close"
							onClick={() =>
								navigate(
									"/" +
										window?.location?.pathname?.split("/")[1] +
										"/" +
										window?.location?.pathname?.split("/")[2] +
										obj.navigationPath,
								)
							}
						>
						<ChevronRight/>
						</Button>
					);
				case "Edit":
					// return <EditIcon />
					return (
						<IconButton
							aria-label="close"
							onClick={() =>
								navigate(
									"/" +
										window?.location?.pathname?.split("/")[1] +
										"/" +
										window?.location?.pathname?.split("/")[2] +
										obj.navigationPath,
								)
							}
						>
							<EditIcon />
						</IconButton>
					);
					break;
				case "Copy":
					// return <ContentCopyIcon />
					return (
						<IconButton aria-label="close">
							<ContentCopyIcon />
						</IconButton>
					);
					break;
				case "Add":
					return (
						<IconButton aria-label="close">
							<AddIcon />
						</IconButton>
					);
					break;
				case "Info":
					return (
						<IconButton aria-label="close">
							<InfoIcon
								sx={{ cursor: "pointer" }}
								onClick={() => {
									onAction(obj.action);
								}}
							/>
						</IconButton>
					);
					break;
				case "History":
					return (
						<IconButton aria-label="close">
							<HistoryIcon
								sx={{ cursor: "pointer" }}
								onClick={() => {
									onAction(obj.action);
								}}
							/>
						</IconButton>
					);
				case "Notes":
						return (
							<IconButton aria-label="close">
								<DescriptionIcon
									sx={{ cursor: "pointer" }}
									onClick={() => {
										onAction(obj.action);
									}}
								/>
							</IconButton>
						);
						break;
				case "Approve":
							return (
								<IconButton aria-label="close">
									<CheckCircleIcon
										sx={{ cursor: "pointer" }}
										onClick={() => {
											onAction(obj.action);
										}}
									/>
								</IconButton>)
								break
				case "Reject":
					return (
						<IconButton aria-label="close">
							<CancelIcon
								sx={{ cursor: "pointer" }}
								onClick={() => {
									onAction(obj.action);
								}}
							/>
						</IconButton>
					);	
					break;	
					case "View":
						return (
							<IconButton aria-label="close">
								<VisibilityIcon
									sx={{ cursor: "pointer" }}
									onClick={() => {
										onAction(obj.action);
									}}
								/>
							</IconButton>
						);	
						break;
						case "Download":
							return (
								<IconButton aria-label="close">
									<Download
										sx={{ cursor: "pointer" }}
									/>
								</IconButton>
							);	
							case "Delete":
								return (
									<IconButton aria-label="close">
										<Delete
											sx={{ cursor: "pointer" }}
										/>
									</IconButton>
								);	
				case "Delete":
					return <DeleteIcon />;
					case "EditAction":
						return (
							<IconButton aria-label="close">
								<EditIcon
									sx={{ cursor: "pointer" }}
									onClick={() => {
										onAction(obj.action);
									}}
								/>
							</IconButton>)
				default:
					break;
			}
		};

		const renderIcons = (obj) => {
			switch (obj) {
				case "Edit":
					// return <EditIcon />
					return (
						<IconButton
							aria-label="close"
							onClick={() =>
								navigate(
									"/" +
										window?.location?.pathname?.split("/")[1] +
										"/" +
										window?.location?.pathname?.split("/")[2] +
										obj.navigationPath,
								)
							}
						>
							<EditIcon />
						</IconButton>
					);
					break;
				case "Copy":
					// return <ContentCopyIcon />
					return (
						<IconButton aria-label="close">
							<ContentCopyIcon />
						</IconButton>
					);
					break;
				case "Open":
					return <OpenInNewIcon />;
					break;
				case "ADD":
					return <AddCircleOutline />;
				default:
					break;
			}
		};
		switch (props.type) {
			case "status-dates":
				return (
					<>
						{(editActionInfo[`${props.parent_table_key}`] && props.data) ||
							props.default_open ? (
							<>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<SelectRenderer
											options={props.options}
											selectedItems={props.options.filter(
												(obj) => obj.value === props.data[`status`],
											)}
											isMulti={false}
											updateSelected={updateState}
										/>
									</Grid>
									<Grid item xs={6}>
										<CustomDateRangePicker
											labeldirection={"column"}
											values={[
												new Date(moment(props.dates[0])),
												new Date(moment(props.dates[1])),
											]}
											onChange={handleDateRangeChange}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<p>
									{props.data ? (
										props.data[`${props.mappingKey}`]
									) : (
										<>{props.value ? props.value.value : null}</>
									)}
								</p>
							</>
						)}
					</>
				);
				break;
			case "select":
				const optionsList = formMasterValuesData.filter((obj) => {
					if (parseInt(obj.parent_id) === props.filter_id) {
						obj.label = obj.name;
						obj.value = obj.name;
						return obj;
					}
				});
				return (
					<>
						{(editActionInfo[`${props.parent_table_key}`] && props.data) ||
							props.default_open ? (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<SelectRenderer
											options={optionsList}
											selectedItems={optionsList.filter(
												(obj) =>
													obj.value === props.data[`${props.mappingKey}`],
											)}
											isMulti={false}
											updateSelected={updateState}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
				break;
			case "textfield":
				return (
					<Grid container spacing={1}>
						<Grid item xs={12}>
							{props.data &&
								((editActionInfo[`${props.parent_table_key}`] &&
									props.data &&
								props.value && selectedRowInfo[`${props.parent_table_key}`] && selectedRowInfo[`${props.parent_table_key}`].filter(obj=> obj[`${props.uniqueKey}`] === props.data[`${props.uniqueKey}`] ).length >0 ) ||
									props.default_open) ? (
								<>
									<div className="spacer"></div>
									<TextFieldWithLabel
										size="small"
										id="filled-hidden-label-normal"
										variant="outlined"
										defaultValue={props.value}
										type={"text"}
										className="ag-theme-custom-react"
										onChange={handleInputChange}
										handleOnBlur={handleOnBlur}
										startAdornment={
											props.value &&
											props.data.position === "start" && (
												<InputAdornment position="start">
													{props.data.symbol}
												</InputAdornment>
											)
										}
										endAdornment={
											props.value && props.data.position === "end" ? (
												<>
													<InputAdornment position="end">
														{props.data.symbol}
														{props.isLockRequired ? (
															<>
																<LockIcon
																	sx={{ fontSize: 15, cursor: "pointer" }}
																	onClick={() => {
																		handleLockOption(props.data);
																	}}
																/>
															</>
														) : null}
													</InputAdornment>
												</>
											) : (
												<>
													{props.isLockRequired ? (
														<>
															<LockIcon
																sx={{ fontSize: 15, cursor: "pointer" }}
																onClick={() => {
																	handleLockOption(props.data);
																}}
															/>
														</>
													) : null}
												</>
											)
										}
									/>
								</>
							) : (
								<>
									<p>
										{props.data ? (
											<>
												{props.data.symbol ? (
													<>
														{props.data.position === "start" &&
															props.data[`${props.mappingKey}`] ? (
															<>
																{props.data.symbol}{" "}
																{props.data[`${props.mappingKey}`]}
															</>
														) : null}
														{props.data.position === "end" &&
															props.data[`${props.mappingKey}`] ? (
															<>
																{props.data[`${props.mappingKey}`]}{" "}
																{props.data.symbol}
															</>
														) : null}
														<></>
													</>
												) : (
													<>{props.data[`${props.mappingKey}`]}</>
												)}
											</>
										) : null}
									</p>
								</>
							)}
						</Grid>
					</Grid>
				);
				break;
			case "valueFormatter":
				return (
					<>
						{props.data && props.data[`${props.mappingKey}`] ? (
							<ActionComponents
								data={{
									components: props.components,
									otherInfo: {
										isLabelAction: props.data[`${props.mappingKey}`],
										actionType: props.actionType,
									},
								}}
							/>
						) : (
							value
						)}
					</>
				);
				break;
			case "textfield-with-lock":
				if (props.data) {
				}
				return (
					<>
						{props.data &&
							props.metricKey &&
							props.metricValues.includes(props.data[`${props.metricKey}`]) &&
							props.conditionValues.indexOf(
								props.data[`${props.conditionKey}`],
							) === -1 ? (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField
											size="small"
											id="filled-hidden-label-normal"
											variant="outlined"
											value={props.data[`${props.mappingKey}`]}
											type={"text"}
											onChange={handleTextFieldChange}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
				break;
			case "actions-list":
				return (
					<span style={{ paddingTop: "22px" }}>
						<>{renderIcons(props.icon)} &nbsp;&nbsp;</>
					</span>
				);
				break;
			case "icon-actions":
				return (
					<>
						<span style={{ ...props.style }}>
							{props.options.map((obj) => (
								<>{obj.displayValue ? <>{props.value} {displayIcons(obj)}</>:displayIcons(obj)} &nbsp;&nbsp;</>
							))}
						</span>
					</>
				);
				break;
			case "actionable-icons":
				return (
					<>
						<span style={{ ...props.style }}>
							{props.options.map((obj) => (
								<>{displayIcons(obj)} &nbsp;&nbsp;</>
							))}
						</span>
					</>
				);
			case "bold":
				return (
					<>
						{props.data && props.data.text_type === "bold" ? (
							<>
								<b>{props.data[`${props.mappingKey}`]}</b>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
				break;
			case "date":
				return (
					<>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<CustomDatePicker labeldirection={"column"} />
							</Grid>
						</Grid>
					</>
				);
			case "time":
				return (
					<>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<TimePicker />
							</Grid>
						</Grid>
					</>
				);
			case "aggregate-link":
				return (
					<>
						{props.isAggregateLevel && typeof props.value === "object" ? (
							<>
								<ActionComponents
									data={{
										components: props.components,
										otherInfo: {
											isLabelAction: props.value.value,
											actionType: props.actionType,
											data: props.data,
											uniqueKey: props.node.key,
										},
									}}
								/>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
			case "link":
				return (
					<>
						{props.value ? (
							<>
								<a href={props.value} target={props.target || "_self"}>
									{props.value}
								</a>
							</>
						) : null}
					</>
				);
			case "TEXTFIELD_ON_CONDITION":
				return(
					<>
							{ props.data && props.conditionValues.indexOf(props.data[`${props.conditionKey}`]) !== -1  ? (
							<TextFieldWithLabel
								size="small"
								id="filled-hidden-label-normal"
								variant="outlined"
								defaultValue={props.value}
								type={"text"}
								className="ag-theme-custom-react"
								onChange={handleInputChange}
								handleOnBlur={handleOnBlur}
								width={props.width || 100}
							/>
							): (
							<>
								{props.data ? (
									<>
										{props.data[`${props.mappingKey}`]}
									</>
							) : null }
							</>
							) }
					</>
				)
			case "custom-navigation":
				return (
					<>
						{props.data && props.data.navigation_type === 'link' ? (
							<>
								<Button
									style={{ textTransform: 'none' }}
									onClick={()=>{
										onAction({actionName:"navigate", path:props.path})
									}}
									autoFocus
									variant='text'
								>
									{props.value}
								</Button>
							</>
						) : (
							<>
								<Button
									style={{ textTransform: 'none' }}
									onClick={()=>{
										console.log("======",props.action)
										onAction(props.action)
									}}
									autoFocus
									variant='text'
								>
									{props.value}
								</Button>
							</>
						)}
					</>
				);
			case "EditTextField":
				return (
					<Grid container spacing={1}>
						<Grid item xs={12}>
							{props.data &&
								((editActionInfo[`${props.parent_table_key}`] &&
									props.data &&
										props.value  ) ||
									props.default_open) ? (
								<>
									<div className="spacer"></div>
									<TextFieldWithLabel
										size="small"
										id="filled-hidden-label-normal"
										variant="outlined"
										defaultValue={props.value}
										type={"text"}
										className="ag-theme-custom-react"
										onChange={handleInputChange}
										handleOnBlur={handleOnBlur}
										startAdornment={
											props.value &&
											props.data.position === "start" && (
												<InputAdornment position="start">
													{props.data.symbol}
												</InputAdornment>
											)
										}
										endAdornment={
											props.value && props.data.position === "end" ? (
												<>
													<InputAdornment position="end">
														{props.data.symbol}
														{props.isLockRequired ? (
															<>
																<LockIcon
																	sx={{ fontSize: 15, cursor: "pointer" }}
																	onClick={() => {
																		handleLockOption(props.data);
																	}}
																/>
															</>
														) : null}
													</InputAdornment>
												</>
											) : (
												<>
													{props.isLockRequired ? (
														<>
															<LockIcon
																sx={{ fontSize: 15, cursor: "pointer" }}
																onClick={() => {
																	handleLockOption(props.data);
																}}
															/>
														</>
													) : null}
												</>
											)
										}
									/>
								</>
							) : (
								<>
									<p>
										{props.data ? (
											<>
												{props.data.symbol ? (
													<>
														{props.data.position === "start" &&
															props.data[`${props.mappingKey}`] ? (
															<>
																{props.data.symbol}{" "}
																{props.data[`${props.mappingKey}`]}
															</>
														) : null}
														{props.data.position === "end" &&
															props.data[`${props.mappingKey}`] ? (
															<>
																{props.data[`${props.mappingKey}`]}{" "}
																{props.data.symbol}
															</>
														) : null}
														<></>
													</>
												) : (
													<>{props.data[`${props.mappingKey}`]}</>
												)}
											</>
										) : null}
									</p>
								</>
							)}
						</Grid>
					</Grid>
				);
				break;
			case "actionComponents":
				return (
					props.data && props.data[`${props.mappingKey}`] ? (
						<div style={{
							minWidth: props?.minWidth ||"auto",
							...props?.customCellStyle
						}}>
							{props?.showValue ?
								<div style={{ textAlign: "center" }}>{props.value}</div>
								: null}
							<ActionComponents
								data={{
									components: props.components,
									style: props?.style,
									params:props
								}}
							/>
						</div>
						) : null
				)
			case "DATEPICKER":
					return (
						<>
							{props.data && props.data[`${props.mappingKey}`] ? (<ReactMuiDatePicker
								value={props.data[`${props.mappingKey}`]}
								{...props}
							/>) : null}
						</>
					)
			default:
				return (
					<>
						{props.data && props.data[`${props.mappingKey}`] ? (
							<ActionComponents
								data={{
									components: props.components,
									otherInfo: {
										isLabelAction: props.data[`${props.mappingKey}`],
										actionType: props.actionType,
										data: props.data,
										type: props.type,
										style: props.style,
										iconType: props.iconType,
										rowIndex: props?.rowIndex || 0,
										params:props
									},
								}}
							/>
						) : null}
					</>
				);
				break;
		}
	};
	function isHidden(props) {		
		if (props.hideForGroup && props.node.group) {
			return true
		}
		const cellValue = props?.data?.[`#hideRenderer_${props.colDef.field}`]
		return (cellValue && cellValue === "Y") ? true : false; 
	}
	return (
		isHidden(props) ? null :
			<>
			{open ? (
				<>
					<CustomModal
						show={open}
						{...props.options[props.index]["action"]}
						handleClose={handleClose}
					/>
				</>
			) : null}
			{/* {show ? <SkuGraphModel show={show} handleClose={handleClose} data={selectedSku} /> : null } */}
			{renderTableComponents()}
		</>
	);
};
