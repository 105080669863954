import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function DropdownCellRenderer(props) {
  let val = props.value || "";
  let varInArr = val.split(",");
  const [value, setValue] = useState(
    props.data?.default_selected_value || varInArr[0]
  );
  const dispatch = useDispatch();
  const { tableInfo } = useSelector((state) => state.home);

  const onValueChange = (event) => {
    setValue(event.target.value);
    if (props.action_type) {
      switch (props.action_type) {
        case "COLUMNS_DATA_UPDATE":
          const tableData = tableInfo[`${props.parent_table_key}`] || [];
          if (tableData.length > 0) {
            tableData.forEach((element, index) => {
              if (props.rowIndex === index) {
                props.columnsList.forEach((colElement) => {
                  element[`${colElement}`] = props.changeValueTo;
                  element[`default_selected_value`] = event.target.value;
                  // element[`default_selected_value${colElement}`] = event.target.value;
                });
              }
            });
            let params = {};
            params[`${props.parent_table_key}`] = tableData;
            dispatch({
              type: "TABLE_DATA",
              payload: params,
            });
          }
          break;

        case "DATA_UPDATE_ON_RESPECTIVE_COLUMNS":
            let tableDataInfo = tableInfo[`${props.parent_table_key}`] || [];
            if (tableDataInfo.length > 0) {
              tableDataInfo.filter((element, index) => {
                if (props.rowIndex === index) {
                  // element[`${props.columnKey}`] = event.target.value;
                  // props.columnsList.forEach((colElement) => {
                  //   element[`${colElement}`] = props.changeValueTo;
                    element[`default_selected_value`] = event.target.value;
                    element[`default_selected_value_${props.columnKey}`] = event.target.value;
                  // });
                }
                // return element
              });
              let params = {};
              params[`${props.parent_table_key}`] = tableDataInfo;
              dispatch({
                type: "TABLE_DATA",
                payload: params,
              });
              if(props["isFormEditedRequired"]){
                let formEditParams ={}
                formEditParams[`${props.columnKey}`] = event.target.value
                dispatch({
                  type: "FORM_EDITED_VALUES",
                  payload: formEditParams,
                });
              }
            }
            break;

        default:
          break;
      }
    }
  };
  return (
    <div>
      {props.data?.hasOwnProperty("#dropdown_disabled") &&
      props.data["#dropdown_disabled"] === "Y"
        ? props.value?.length > 0 && (
            <select
              value={value}
              onChange={onValueChange}
              style={{ height: "2rem" }}
              disabled="true"
            >
              {varInArr.map((el, index) => {
                return <option value={el}>{el}</option>;
              })}
            </select>
          )
        : props.value?.length > 0 && (
            <>
              <select
              value={value}
              onChange={onValueChange}
              style={{ height: "2rem", width: props.width || null }}
            >
              {varInArr.map((el, index) => {
                return <option value={el}>{el}</option>;
              })}
            </select>
            </>
          )}
    </div>
  );
}

export default DropdownCellRenderer;
