import React, { useState, useEffect } from "react";
import CustomActionButton from "./CustomActionButton";
import CustomRadioGroup from "./CustomRadioGroup";
import {
	FormControlLabel,
	Grid,
	Switch,
	Typography,
	Button,
} from "@mui/material";
import ComponentResolver from "../Resolver/ComponentResolver";
import CheckboxGroup from "../../../components/CheckboxGroup";
import { useDispatch, useSelector } from "react-redux";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import { groupBy, mapValues, _ } from "lodash";
import DropdownTreeSelectRenderer from "../../../components/dropdownTreeSelect";
import { fetchHierarchyData } from "../DynamicFilters/api";
import CustomRadioButtons from "../CustomComponents/CustomRadioButtons";
import ButtonDropdown from "../CustomComponents/ButtonDropdown";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import CustomDateRange from "../CustomComponents/CustomDateRange";
import VerticleTables from "../CustomComponents/verticleTables";
import HorizontalCharts from "../CustomComponents/horizontalCharts";
import VerticalCharts from "../CustomComponents/verticalCharts";
import CustomisedCard from "./customisedCard";
import KpiCardComponent from "./KpiCardComponent";
import Calendar from "../../../components/Calendar";
import Steppers from "../../../components/Steppers/Steppers";
import Tabs from "../Resolver/TabResolver";
import CustomCartersKPICard from "../CustomComponents/CustomCartersKPICard";
import { Link, useNavigate } from "react-router-dom";
import CustomToggle from "./CustomToggle";
import Text from "../../../utils/Text";
import CustomCounterButton from "./CustomCounterButton";
import SearchBar from "./SearchBar";
import ButtonWithIcon from "./ButtonWithIcon";
import CustomImage from "./CustomImage";
import SplitTableGeneric from "./SplitTableGeneric";
import SplitTableLululemon from "./SplitTableLululemon";
import { updateLineChartData } from "../data";
import ColorScale from "./ColorScale";
import ImageDisplay from "./ImageDisplay";
import TextArea from "../../../components/TextArea";
import CustomAccordian from "./CustomAccordian";
import useAction from "../../../utils/useAction";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PriceCheckbox1 from "./PriceCheckbox1";
import PriceCheckbox2 from "./PriceCheckbox2";
import MappedSelectRenderer from "../../../components/Select/MappedSelectRenderer";
const ActionComponents = (props) => {
	const { data } = props;
	const rootUrl = window?.location?.pathname.split("/");
	const baseUrl = `/${rootUrl[1]}/${rootUrl[2]}`;
	const dispatch = useDispatch();
	const { tableInfo, dependentInfo, formEditedValues, columnsInfo,gridRefs } =
		useSelector((state) => state.home);
	const { formMasterValuesData, formMenuData } = useSelector(
		(state) => state.filter,
	);
	const invokeAction = useAction()
	const { defaultDependentOn, defaultActionIndex, otherKeyDependentOn,disableDefaultDependentKeys } = data;
	const [selectedOption, setSelectedOption] = useState({});
	const [childComponentsList, setChildComponentsList] = useState([]);
	const [componentsList, setComponentsList] = useState([]);
	const [dropdownSelection, setDropdownSelection] = useState({});
	const [check, setcheck] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		if (defaultActionIndex !== null && defaultDependentOn) {
			if (props.data && props.data["isStoreRequired"]) {
				let params = {};
				if (
					!(
						data?.defaultModifier||data?.preserveDependents &&
						dependentInfo.hasOwnProperty(defaultDependentOn)
					)
				) {
					if (Array.isArray(defaultDependentOn)) {
						defaultDependentOn.forEach(key=>params[key]=true)
					}
					else
						params[`${defaultDependentOn}`] = true;
				}
				if (otherKeyDependentOn) {
					params[`${otherKeyDependentOn}`] = false;
				}
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: params,
				});
			}
			setSelectedOption({
				index: defaultActionIndex,
				dependentOn: defaultDependentOn,
			});
		} else {
			if (data["components"]) {
				setSelectedOption({});
				setChildComponentsList([]);
			}
		}
		if (data["components"]) {
			setComponentsList(data.components);
		}
		if(disableDefaultDependentKeys){
			if (Array.isArray(disableDefaultDependentKeys)) {
				let params = {};
				disableDefaultDependentKeys.forEach(key=>params[key]=false)
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: params,
				});
			}
		}
	}, [data.components]);

	useEffect(() => {
		setChildComponentsList([]);
		if (Object.keys(selectedOption).length > 0) {
			if (data.components[selectedOption.index]&& data.components[selectedOption.index]["childComponents"]) {
				const componentsList = data.components[
					selectedOption.index
				].childComponents.filter(
					(obj) => obj.dependentOn === selectedOption.dependentOn,
				);
				setChildComponentsList(componentsList);
			}
		}
	}, [selectedOption]);

	const handleRadioChange = (e, obj, index) => {
		const { name, value } = e.target;
		if (props.data && props.data["isStoreRequired"]) {
			let params = {};
			obj.options.forEach((option) => {
				if (option.value === value) {
					params[`${value}`] = true;
				} else {
					params[`${option.value}`] = false;
				}
			});
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		}
		setSelectedOption({
			index: index,
			dependentOn: value,
		});
	};
	const handleCheckboxChange = (e, obj, index) => {
		const { name, value } = e.target;
		if (obj["isStoreRequired"]) {
			let params = {};
			params[`${name}`] = dependentInfo[`${name}`]
				? !dependentInfo[`${name}`]
				: true;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else {
			setSelectedOption({
				index: index,
				dependentOn: name,
			});
		}
	};
	const onLabelActionClick = (obj, index) => {
		if (obj.value) {
			if (props.data && props.data["isStoreRequired"]) {
				let params = {};
				params[`${obj.value}`] = dependentInfo[`${obj.value}`]
					? !dependentInfo[`${obj.value}`]
					: true;
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: params,
				});
			}
			setSelectedOption({
				index: index,
				dependentOn: obj.value,
			});
		}
	};
	const handleAction = (params,action,optionsList) => {
		switch (action.type) {
		case "DISPLAY_BASED_ON_SELECTIONS": {
			const exceptions = new Set(action?.exceptions);
			let selectedValue = params.selectedItems.map(
				(filterObj) => filterObj.id,
			);
			if (selectedValue.length > 0) {
				let dropdownParams = {};
				let value = selectedValue[0];
				if (!exceptions.has(value)) {
					if (optionsList.length > 0) {
						optionsList.forEach((obj) => {
							if (action.unique_id) {
								dropdownParams[`${action.unique_id}_${obj.id}`] = value == obj.id;
							} else {
								dropdownParams[`${obj.id}`] = value == obj.id;
							}
						});
					} else {
						dropdownParams[`${value}`] = dependentInfo[`${value}`]
							? !dependentInfo[`${value}`]
							: true;
					}
					if (action.hideOtherDependents) {
						action.hideOtherDependents.forEach((obj) => {
							dropdownParams[`${obj}`] = false;
						});
					}
					console.log(dropdownParams);
					dispatch({
						type: "DEPENDENT_COMPONENTS",
						payload: dropdownParams,
					});
				}
			}
			break;
			}
			case "ADD_SELECTION_TO_ROW": {
				let selectedValue = params.selectedItems.map(
					(filterObj) => filterObj.value,
			);
				if (selectedValue.length > 0) {
					let value = selectedValue[0];
					const rows = [...tableInfo[action.dest_table_key]]
					if (rows.length > 0) {
						const selectedRows = gridRefs[action.dest_table_key].current.api.getSelectedNodes()
						if (selectedRows && selectedRows.length > 0) {
							const selectedRowsIndexes = new Set(selectedRows.map(node=>node.rowIndex))
							rows.forEach((row, index) => {
								if (selectedRowsIndexes.has(index))
									row[action.dest_column] = value
						})
						} else {
							rows[rows.length - 1][action.dest_column] = value	
						}
						dispatch({
							type: "TABLE_DATA",
							payload: rows,
						});
					}
					}
				}
				break;
	}
}
	const handleSelect = (params, compObj, filter_label, optionsList) => {
		let reqParams = { ...dropdownSelection };
		reqParams[`${filter_label}`] = params.selectedItems;
		if (compObj.action) {
			if (Array.isArray(compObj.action)) {
				compObj.action.forEach(action => handleAction(params,action,optionsList))
			} else {
				switch (compObj.action.type) {
					case "UPDATE_PLAN_TABLE":
						let metricData = [];
						let payload = {};
						if (compObj.action.source_table_key) {
							metricData = tableInfo[`${compObj.action.source_table_key}`];
							payload[`${compObj.action.parent_table_key}`] = [...metricData];
						} else {
							metricData = tableInfo[`${compObj.action.parent_table_key}`];
							const metricGroupData = mapValues(
								groupBy(metricData, "metric_level"),
							);
							const updatedData = metricData.filter((obj) => {
								const newData = metricGroupData[`${obj.metric_level}`];
								if (newData && obj[`ref`] === "WP") {
									if (params.selectedItems.length > 0) {
										const wpData = newData.filter(
											(wpObj) => wpObj[`ref`] === params.selectedItems[0].name,
										);
										let reqObj = { ...wpData[0] };
										Object.keys(reqObj).forEach((metricName) => {
											if (metricName !== "ref") {
												obj[`${metricName}`] = reqObj[`${metricName}`];
											}
										});
									} else {
										Object.keys(obj).forEach((metricName) => {
											if (metricName !== "ref") {
												obj[`${metricName}`] = 0;
											}
										});
									}
	
									return obj;
								}
								return obj;
							});
							payload[`${compObj.action.parent_table_key}`] = [...updatedData];
						}
	
						dispatch({
							type: "TABLE_DATA",
							payload: payload,
						});
						break;
					case "UPDATE_PIVOT_TABLE":
						if (dependentInfo[`Change Axis`]) {
							if (columnsInfo[`tb_plan_enable_pivot_axis`]) {
								const updatedColumns = [];
								const updatedColumnsList = [
									...columnsInfo[`tb_plan_enable_pivot_axis_copy`],
								];
								updatedColumnsList.map((parentCol) => {
									let updateReq = { ...parentCol };
									if (parentCol["children"] && params.selectedItems.length > 0) {
										const childColumnsList = [];
										const selectedValues = params.selectedItems.map(
											(obj) => obj.name,
										);
										const childColumns = parentCol["children"].filter(
											(childCol) => {
												if (selectedValues.indexOf(childCol.headerName) !== -1) {
													return childCol;
												}
											},
										);
										updateReq[`children`] = childColumns;
									}
									updatedColumns.push(updateReq);
								});
								let columnReq = {};
								columnReq["tb_plan_enable_pivot_axis"] = [...updatedColumns];
								dispatch({
									type: "COLUMN_DATA",
									payload: columnReq,
								});
							}
						} else {
							const newBrandsData = [];
							const pivotData = tableInfo[`${compObj.action.parent_table_key}`];
							const copyData = tableInfo[`${compObj.action.parent_table_key}`];
							const selectedValues = params.selectedItems
								.filter((obj) => obj.isNewBrand === undefined)
								.map((obj) => obj.name);
							const isNewBrand = params.selectedItems.filter(
								(obj) => obj.isNewBrand === true,
							);
							const pivotUpdatedData = copyData.filter((obj) => {
								console.log(
									"Selected Values",
									selectedValues,
									selectedValues.includes(obj["class"]),
									obj["class"],
								);
								if (
									selectedValues.includes(obj["class"]) ||
									obj["class"] === "Total"
								) {
									return obj;
								}
							});
	
							if (isNewBrand.length > 0) {
								const brandGroupLevel = mapValues(groupBy(copyData, "metric"));
								Object.keys(brandGroupLevel).forEach((metricData) => {
									isNewBrand.forEach((newBrandObj) => {
										// if(pivotData)
										if (
											compObj.action["options"] &&
											compObj.action["options"].length > 0
										) {
											compObj.action["options"].forEach((nameData) => {
												let params = {};
												Object.keys(copyData[0]).forEach((pivotData) => {
													params[`${pivotData}`] = 0;
												});
												params[`metric`] = metricData;
												params[`ref`] = nameData;
												params[`class`] = newBrandObj.name;
												params[`department`] = newBrandObj.department;
												newBrandsData.push(params);
											});
										}
									});
								});
							}
							let payloadReq = {};
							payloadReq[`${compObj.action.parent_table_key}`] = [
								...pivotUpdatedData,
								// ...copyData,
								...newBrandsData,
							];
							console.log("New brands Data", payloadReq);
							dispatch({
								type: "TABLE_DATA",
								payload: payloadReq,
							});
						}
	
						break;
					case "tab-redirect":
						if (compObj.action.path) {
							// navigate(action.path);
						}
	
						dispatch({
							type: "TAB_REDIRECT",
							payload: {
								activeTabIndex: compObj.action.activeTabIndex,
								parentTabIndex: compObj.action.parentTabIndex,
								activeTabValue: compObj.action.activeTabValue,
								parentTabValue: compObj.action.parentTabValue,
							},
						});
						if (compObj.action["otherTableUpdate"]) {
							setTimeout(() => {
								let columnReq = {};
								columnReq[`${compObj.action["sourceTableName"]}`] = [
									...tableInfo[`${compObj.action["otherTableUpdate"]}`],
								];
								dispatch({
									type: "TABLE_DATA",
									payload: columnReq,
								});
							}, 1000);
						}
						break;
					case "RENDER_KPI_TABLE":
						let payloadReq = {};
						if (params.selectedItems.length > 0) {
							if (params.selectedItems[0].id === compObj.action.key) {
								payloadReq[`${compObj.action.key}`] = true;
								payloadReq[`${compObj.action.otherKey}`] = false;
								payloadReq[`${compObj.action.otherKey2}`] = compObj.action
									.otherKey2
									? false
									: null;
							} else {
								payloadReq[`${compObj.action.otherKey}`] = true;
								payloadReq[`${compObj.action.key}`] = false;
								payloadReq[`${compObj.action.otherKey2}`] = compObj.action
									.otherKey2
									? false
									: null;
							}
						}
	
						dispatch({
							type: "DEPENDENT_COMPONENTS",
							payload: payloadReq,
						});
						break;
	
					case "ENABLE_DISABLE_DROPDOWN_KEYS":
						let updatedParams = {};
						if (params.selectedItems.length > 0) {
							updatedParams[`${params.selectedItems[0].id}`] = true;
							if (`${params.selectedItems[0].id}` === `${compObj.action.key1}`) {
								updatedParams[`${compObj.action.key2}`] = false;
							} else {
								updatedParams[`${compObj.action.key1}`] = false;
							}
							updatedParams[`${compObj.action.otherKey1}`] = compObj.action
								.otherKey1
								? false
								: null;
							updatedParams[`${compObj.action.otherKey2}`] = compObj.action
								.otherKey2
								? false
								: null;
							updatedParams[`${compObj.action.otherKey3}`] = compObj.action
								.otherKey3
								? false
								: null;
							updatedParams[`${compObj.action.otherKey4}`] = compObj.action
								.otherKey4
								? false
								: null;
							updatedParams[`${compObj.action.otherKey5}`] = compObj.action
								.otherKey5
								? false
								: null;
							updatedParams[`${compObj.action.otherKey6}`] = compObj.action
								.otherKey6
								? false
								: null;
							updatedParams[`${compObj.action.otherKey7}`] = compObj.action
								.otherKey7
								? false
								: null;
							updatedParams[`${compObj.action.otherKey8}`] = compObj.action
								.otherKey8
								? false
								: null;
							updatedParams[`${compObj.action.otherKey9}`] = compObj.action
								.otherKey9
								? false
								: null;
							updatedParams[`${compObj.action.otherKey10}`] = compObj.action
								.otherKey10
								? false
								: null;
						}
						dispatch({
							type: "DEPENDENT_COMPONENTS",
							payload: updatedParams,
						});
						break;
	
					case "UPDATE_INSEASON_PLAN_TABLE":
						let inseasonReq = {};
						inseasonReq[`${compObj.action.parent_table_key}`] = [
							...tableInfo[`${compObj.action.source}`],
						];
						dispatch({
							type: "TABLE_DATA",
							payload: inseasonReq,
						});
						break;
					case "UPDATE_USER_SELECTION":
						let selectParams = {};
						const selectedValues = params.selectedItems.map(
							(filterObj) => filterObj.name,
						);
						let selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
							(filterObj) =>
								selectedValues.indexOf(
									filterObj[`${compObj.action.mappingKey}`],
								) !== -1,
						);
						if (
							compObj.action.otherKey === "Region" &&
							formEditedValues[`${compObj.action.otherKey}`] &&
							formEditedValues[`${compObj.action.otherKey}`].length > 0
						) {
							if (
								compObj.action.mappingKey === "Cluster" &&
								selectedValues.length === 0
							) {
								selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
									(filterObj) =>
										formEditedValues[`${compObj.action.otherKey}`].indexOf(
											filterObj[`${compObj.action.otherKey}`],
										) !== -1,
								);
							} else {
								selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
									(filterObj) =>
										selectedValues.indexOf(
											filterObj[`${compObj.action.mappingKey}`],
										) !== -1 &&
										formEditedValues[`${compObj.action.otherKey}`].indexOf(
											filterObj.Cluster,
										) !== -1,
								);
							}
						} else if (
							compObj.action.otherKey === "Cluster" &&
							formEditedValues[`${compObj.action.otherKey}`] &&
							formEditedValues[`${compObj.action.otherKey}`].length > 0
						) {
							if (
								compObj.action.mappingKey === "Region" &&
								selectedValues.length === 0
							) {
								selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
									(filterObj) =>
										formEditedValues[`${compObj.action.otherKey}`].indexOf(
											filterObj[`${compObj.action.otherKey}`],
										) !== -1,
								);
							} else {
								selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
									(filterObj) =>
										selectedValues.indexOf(
											filterObj[`${compObj.action.mappingKey}`],
										) !== -1 &&
										formEditedValues[`${compObj.action.otherKey}`].indexOf(
											filterObj.Cluster,
										) !== -1,
								);
							}
						} else if (
							formEditedValues[`${compObj.action.otherKey}`] &&
							formEditedValues[`${compObj.action.otherKey}`].length > 0
						) {
							selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
								(filterObj) =>
									selectedValues.indexOf(
										filterObj[`${compObj.action.otherKey}`],
									) !== -1,
							);
						}
						selectParams[`${compObj.action.destinationKey}`] = [...selectedRows];
						let formEditing = {};
						formEditing[`${compObj.action.mappingKey}`] = selectedValues;
						dispatch({
							type: "TABLE_DATA",
							payload: selectParams,
						});
						dispatch({
							type: "FORM_EDITED_VALUES",
							payload: formEditing,
						});
					case "IS_STORE_REQUIRED":
						let dropdownValue = params.selectedItems.map(
							(filterObj) => filterObj.name,
						);
						if (dropdownValue.length > 0) {
							let dropdownParams = {};
							let value = dropdownValue[0];
							if (
								compObj.action.toggleOptions &&
								compObj.action.toggleOptions.length > 0
							) {
								compObj.action.toggleOptions.forEach((obj) => {
									if (value == obj) {
										dropdownParams[`${value}`] = true;
									} else {
										dropdownParams[`${obj}`] = false;
									}
								});
							} else {
								dropdownParams[`${value}`] = dependentInfo[`${value}`]
									? !dependentInfo[`${value}`]
									: true;
							}
							let formEditing = {};
							formEditing[`${compObj.form_label}`] = params.selectedItems;
							dispatch({
								type: "FORM_EDITED_VALUES",
								payload: formEditing,
							});
							dispatch({
								type: "DEPENDENT_COMPONENTS",
								payload: dropdownParams,
							});
						}
					case "IS_FORM_EDIT_REQUIRED":
						let formEditingParams = {};
						formEditingParams[`${compObj.form_label}`] = params.selectedItems;
						dispatch({
							type: "FORM_EDITED_VALUES",
							payload: formEditingParams,
						});
					case "DISPLAY_BASED_ON_SELECTION": {
						let selectedValue = params.selectedItems.map(
							(filterObj) => filterObj.name,
						);
						if (selectedValue.length > 0) {
							let dropdownParams = {};
							let value = selectedValue[0];
							if (optionsList.length > 0) {
								optionsList.forEach((obj) => {
									if (value == obj.value) {
										dropdownParams[`${value}`] = true;
									} else {
										dropdownParams[`${obj.value}`] = false;
									}
								});
							} else {
								dropdownParams[`${value}`] = dependentInfo[`${value}`]
									? !dependentInfo[`${value}`]
									: true;
							}
							if (compObj.action.hideOtherDependents) {
								compObj.action.hideOtherDependents.forEach((obj) => {
									dropdownParams[`${obj}`] = false;
								});
							}
							console.log(dropdownParams);
							dispatch({
								type: "DEPENDENT_COMPONENTS",
								payload: dropdownParams,
							});
						}
						break;
					}
					case "DISPLAY_BASED_ON_SELECTIONS": {
						const exceptions = new Set(compObj.action.exceptions);
						let selectedValue = params.selectedItems.map(
							(filterObj) => filterObj.id,
						);
						if (selectedValue.length > 0) {
							let dropdownParams = {};
							let value = selectedValue[0];
							if (!exceptions.has(value)) {
								if (optionsList.length > 0) {
									optionsList.forEach((obj) => {
										dropdownParams[`${obj.id}`] = value == obj.id;
									});
								} else {
									dropdownParams[`${value}`] = dependentInfo[`${value}`]
										? !dependentInfo[`${value}`]
										: true;
								}
								if (compObj.action.hideOtherDependents) {
									compObj.action.hideOtherDependents.forEach((obj) => {
										dropdownParams[`${obj}`] = false;
									});
								}
								console.log(dropdownParams);
								dispatch({
									type: "DEPENDENT_COMPONENTS",
									payload: dropdownParams,
								});
							}
						}
						break;
					}
					case "UPDATE_TABLE_COLUMNS":
						if (columnsInfo[`${compObj.action.source}`]) {
							const updatedColumns = [];
							const updatedColumnsList = [
								...columnsInfo[`${compObj.action.destination}`],
							];
							updatedColumnsList.map((parentCol) => {
								let updateReq = { ...parentCol };
								if (parentCol["children"]) {
									let childColumnsList = parentCol["children"].map(
										(columnObj) => columnObj.headerName,
									);
									if (params.selectedItems.length > 0) {
										const selectedValues = params.selectedItems.map(
											(obj) => obj.name,
										);
										selectedValues.forEach((selectedObj) => {
											if (childColumnsList.indexOf(selectedObj) === -1) {
												compObj.action.options.forEach((colOb) => {
													parentCol["children"].push({
														headerName: colOb ? `${colOb}` : `${selectedObj}`,
														field: colOb
															? `${parentCol.field}_${selectedObj}_${colOb}`
															: `${parentCol.field}_${selectedObj}`,
													});
												});
											}
										});
										optionsList.forEach((optionObj) => {
											let selectedOptionIndex = selectedValues.indexOf(
												optionObj.value,
											);
											if (selectedOptionIndex === -1) {
												let optionIndex = childColumnsList.indexOf(
													optionObj.value,
												);
												if (optionIndex !== -1) {
													updateReq["children"].splice(optionIndex, 2);
												}
											}
										});
									} else {
										optionsList.forEach((optionObj) => {
											console.log(childColumnsList.indexOf(optionObj.value));
											let optionIndex = childColumnsList.indexOf(optionObj.value);
											if (optionIndex !== -1) {
												updateReq["children"].splice(optionIndex, 2);
											}
										});
									}
								}
								updatedColumns.push(updateReq);
							});
							let columnReq = {};
							columnReq[`${compObj.action.source}`] = [...updatedColumns];
							dispatch({
								type: "COLUMN_DATA",
								payload: columnReq,
							});
						}
	
						break;
					case "UPDATE_CHART_DATA":
						let chartParams = {};
						let chartData = [];
						let optionsObj = {};
						if (tableInfo[`${compObj.action.source_table_key}`]) {
							switch (compObj.action.actionType) {
								case "Department":
									chartData = tableInfo[
										`${compObj.action.source_table_key}`
									].filter(
										(obj) =>
											params.selectedItems
												.map((selectedObj) => selectedObj.value)
												.indexOf(obj.department) !== -1,
									);
									// compObj.action.dependentFilters.forEach(obj=>{
									//   optionsObj[`${obj.filter_id}`] = convertToDropdownValues(_.uniq(chartData.map(chartObj=> chartObj[`${obj.key}`])));
									// })
									// setDropdownSelection({
									//   ...dropdownSelection,
									//   ...optionsObj
									// })
									chartParams[`${compObj.action.parent_table_key}`] =
										updateLineChartData(chartData, compObj.action.xAxisLabels);
									break;
	
								case "Class":
									chartData = tableInfo[
										`${compObj.action.source_table_key}`
									].filter(
										(obj) =>
											params.selectedItems
												.map((selectedObj) => selectedObj.value)
												.indexOf(obj.class) !== -1,
									);
									// compObj.action.dependentFilters.forEach(obj=>{
									//   optionsObj[`${obj.filter_id}`] = convertToDropdownValues(_.uniq(chartData.map(chartObj=> chartObj[`${obj.key}`])));
									// })
									// setDropdownSelection({
									//   ...dropdownSelection,
									//   ...optionsObj
									// })
									chartParams[`${compObj.action.parent_table_key}`] =
										updateLineChartData(chartData, compObj.action.xAxisLabels);
									break;
								case "Store Cluster":
									chartData = tableInfo[
										`${compObj.action.source_table_key}`
									].filter(
										(obj) =>
											params.selectedItems
												.map((selectedObj) => selectedObj.value)
												.indexOf(obj.store_cluster) !== -1,
									);
									// compObj.action.dependentFilters.forEach(obj=>{
									//   optionsObj[`${obj.filter_id}`] = convertToDropdownValues(_.uniq(chartData.map(chartObj=> chartObj[`${obj.key}`])));
									// })
									// setDropdownSelection({
									//   ...dropdownSelection,
									//   ...optionsObj
									// })
									chartParams[`${compObj.action.parent_table_key}`] =
										updateLineChartData(chartData, compObj.action.xAxisLabels);
									break;
	
								default:
									break;
							}
	
							dispatch({
								type: "TABLE_DATA",
								payload: chartParams,
							});
						}
	
						break;
					case "ADD_NEW_COLUMNS_TO_TABLE":
						if (columnsInfo[`${compObj.action.source}`]) {
							const updatedColumns = [];
							const updatedColumnsList = [
								...columnsInfo[`${compObj.action.source}`],
							];
	
							if (params.selectedItems && params.selectedItems.length > 0) {
								params.selectedItems.forEach((obj) => {
									if (compObj.action.options[`${obj.value}`]) {
										compObj.action.options[`${obj.value}`].forEach((colName) => {
											let colList = columnsInfo[
												`${compObj.action.columnsList}`
											].filter((filterObj) => filterObj.field === colName);
											if (colList.length > 0) {
												updatedColumnsList.push(colList[0]);
											}
										});
									}
								});
							}
							let columnReq = {};
							columnReq[`${compObj.action.destination}`] = [
								...updatedColumnsList,
							];
							dispatch({
								type: "COLUMN_DATA",
								payload: columnReq,
							});
						}
	
						break;
					case "navigate":
						if (
							params.selectedItems[0].redirect_pathname !== null &&
							params.selectedItems[0].redirect_pathname !== ""
						) {
							navigate(
								"/" +
									window?.location?.pathname?.split("/")[1] +
									"/" +
									window?.location?.pathname?.split("/")[2] +
									params.selectedItems[0].redirect_pathname,
							);
						}
						break;
					case "COPY_DATA_BASED_ON_SELECTION":
	
							if (params.selectedItems && params.selectedItems.length > 0) {
								if(params.selectedItems.map(obj=> obj.value).indexOf(compObj.action.conditionValue) !==-1){
									let tableReq = {};
									tableReq[`${compObj.action.parent_table_key}`] = [
										...tableInfo[`${compObj.action.source_table_key}`],
									];
									dispatch({
										type: "TABLE_DATA",
										payload: tableReq,
									});
								}
								
							}
						break;
					//adds selected value from dropdown to the a column in table row
					case "ADD_SELECTION_TO_ROW": {
							let selectedValue = params.selectedItems.map(
								(filterObj) => filterObj.value,
						);
							if (selectedValue.length > 0) {
								let value = selectedValue[0];
								const rows = [...tableInfo[compObj.action.dest_table_key]]
								if (rows.length > 0) {
									const selectedRows = gridRefs[compObj.action.dest_table_key].current.api.getSelectedNodes()
									if (selectedRows && selectedRows.length > 0) {
										const selectedRowsIndexes = new Set(selectedRows.map(node=>node.rowIndex))
										rows.forEach((row, index) => {
											if (selectedRowsIndexes.has(index))
												row[compObj.action.dest_column] = value
									})
									} else {
										rows[rows.length - 1][compObj.action.dest_column] = value	
									}
									dispatch({
										type: "TABLE_DATA",
										payload: rows,
									});
								}
								}
							}
						break;
					case "DROPDOWN_EDIT":
							let formEditParams = {};
							formEditParams[`${compObj.form_label}`] = params.selectedItems;
							dispatch({
								type: "FORM_EDITED_VALUES",
								payload: formEditParams,
							});
							break;
					case "REPLACE_TABLE":
						const updatedData = tableInfo[`${compObj.action.final_table_key}`];
						payload = {};
						payload[`${compObj.action.initial_table_key}`] = [...updatedData];
						dispatch({
							type: "TABLE_DATA",
							payload: payload,
						});
						break;
					case "ADD_DROPDOWN_MAPPING": {
						const selected = params.selectedItems
						if (selected) {
							const selectedValue = parseInt(selected[0].map_id)
							dispatch({
								type: "DROPDOWN_MAP",
								payload: { [compObj.uniqueKey]: selectedValue }
							})
						}
					}
					default:
						break;
				}
				
			}
			
		}
		setDropdownSelection(reqParams);
		dispatch({
			type: "GET_DROPDOWN_SELECTION_DATA",
			payload: reqParams,
		});
	};
	const handleTextFieldChange = (e, field) => {
		const { name, value } = e.target;
		if (field["isStoreRequired"]) {
			let params = {};
			params[`${name}`] = value;
			dispatch({
				type: "FORM_EDITED_VALUES",
				payload: params,
			});
		}
	};

	const switchhandler = (abc) => {
		if (abc.action.actionName === "click") {
			let params = {};

			params[`${abc.action.key}`] = dependentInfo[`${abc.action.key}`]
				? !dependentInfo[`${abc.action.key}`]
				: true;

			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		}
		else if (abc.action.actionName === "ENABLE_DISABLE_KEYS") {
			let params = {};
			params[abc.action.key] = true;
			params[abc.action.otherKey] = false;
			params[abc.action.otherKey2] = abc.action.otherKey2 ? false : null;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		}
	};
	const onDropdownTreeChange = (currentNode, selectedNodes, actionObj) => {
		console.log("onChange::", currentNode, selectedNodes, actionObj);
		if (actionObj && actionObj.values.indexOf(currentNode.name) !== -1) {
			if (actionObj.actionName === "COPY_TABLE_DATA_OTHER_TABLE") {
				const metricData = tableInfo[`${actionObj.source}`];
				let payload = {};
				payload[`${actionObj.dest}`] = [...metricData];
				// setTimeout(() => {
				dispatch({
					type: "TABLE_DATA",
					payload: payload,
				});
				// }, 5);
			}
		}
	};
	const onDropdownTreeAction = (node, action) => {
		console.log("onAction::", action, node);
	};
	const onDropdownTreeNodeToggle = (currentNode) => {
		console.log("onNodeToggle::", currentNode);
	};

	const renderActionComponents = (componentInfo, index) => {
		const { type } = componentInfo;
		switch (type) {
			case "toggler":
				return <CustomToggle data={componentInfo} />;
			case "radio":
				return <CustomRadioButtons data={componentInfo} />;
			case "date-range-picker":
				return <CustomDateRange data={componentInfo} />;
			case "counterButton":
				return <CustomCounterButton data={componentInfo} params={data?.params} />;
			case "buttonWithIcon":
				return <ButtonWithIcon data={data} />;
			case "textField":
				return (
					<>
						{componentInfo.info.map((field) => (
							<TextFieldWithLabel
								{...field}
								type={field.type}
								label={field.label}
								width={field.width}
								defaultValue={field.defaultValue}
								name={field.name}
								onChange={(e) => {
									handleTextFieldChange(e, field);
								}}
							/>
						))}
					</>
				);
			case "CustomRadioGroup":
				return (
					<>
						<CustomRadioGroup
							label={componentInfo.label}
							options={componentInfo.options}
							row={true}
							values={1}
							handleChange={(e) => handleRadioChange(e, componentInfo, index)}
							checkedValue={selectedOption.dependentOn}
							horizontalView={componentInfo.horizontalView}
							clearDependentsOnDestroy={componentInfo?.clearDependentsOnDestroy}
						/>
					</>
				);
				break;
			case "Checkboxes":
				return (
					<div style={componentInfo.style}>
						<CheckboxGroup
							label={componentInfo.label}
							options={componentInfo.options}
							row={!componentInfo.column}
							values={1}
							handleChange={(e) =>
								handleCheckboxChange(e, componentInfo, index)
							}
						/>
					</div>
				);
				break;
			case "Checkbox1":
				return (
					<div style={componentInfo.style}>
						<PriceCheckbox1
							label={componentInfo.label}
							options={componentInfo.options}
							row={!componentInfo.column}
							values={1}
						/>
					</div>
				);
			case "Checkbox2":
				return (
					<div style={componentInfo.style}>
						<PriceCheckbox2
							label={componentInfo.label}
							options={componentInfo.options}
							row={!componentInfo.column}
							values={1}
						/>
					</div>
				);
			case "bottomActionButton":
				return (
					<>
						<CustomActionButton
							data={componentInfo}
							itemIndex={index}
							otherInfo={props.data.otherInfo}
						/>
					</>
				);

			case "actionButton":
				return (
					<>
						{componentInfo[`dependentOn`] ? (
							<>
								{dependentInfo[`${componentInfo[`dependentOn`]}`] ? (
									<CustomActionButton
										data={componentInfo}
										itemIndex={index}
										otherInfo={props.data.otherInfo}
									/>
								) : null}
							</>
						) : (
							<CustomActionButton
								data={componentInfo}
								itemIndex={index}
								otherInfo={props.data.otherInfo}
							/>
						)}
					</>
				);
			case "buttonDropdown":
				return <ButtonDropdown data={componentInfo} />;
			case "forms":
				return (
					<>
						<ComponentResolver
							pageObject={{ components: componentInfo.components }}
						/>
					</>
				);
			case "filters":
				return (
					<>
						<ComponentResolver pageObject={{ components: [componentInfo] }} />
					</>
				);
			case "stepper":
				return <Steppers data={componentInfo} />;

			case "search":
				return <SearchBar data={componentInfo} />;
			case "label":
				return (
					<>
						{componentInfo.options.map((obj, childIndex) => (
							<>
								<div
									onClick={() => {
										onLabelActionClick(obj, index);
									}}
									style={{ cursor: "pointer", color: "#0255AF" }}
								>
									{obj.label}
								</div>
							</>
						))}
					</>
				);
			case "text":
				return (
					<div style={componentInfo.style}>
						<Text
							sx={componentInfo?.textStyle}
							weight={componentInfo?.textStyle?.fontWeight}
						>
							{componentInfo.name}
						</Text>
					</div>
				);
			case "table":
				return (
					<div style={componentInfo.style}>
						<VerticleTables tables={componentInfo.info} />
					</div>
				);
			case "verticalCharts":
				return <VerticalCharts charts={componentInfo.info} />;
			case "mappedSelect":
				return <MappedSelectRenderer
					formMasterValuesData={formMasterValuesData}
					formMenuData={formMenuData}
					componentInfo={componentInfo}
					handleSelect={handleSelect}
					dropdownSelection={dropdownSelection}
				/>
			case "select":
				const optionsList = formMasterValuesData.filter((obj) => {
					if (parseInt(obj.parent_id) === componentInfo.filter_id) {
						obj.label = obj.name;
						obj.value = obj.name;
						return obj;
					}
				});
				const defaultValue = formMasterValuesData.filter((obj) => {
					if (parseInt(obj.parent_id) === componentInfo.filter_id) {
						if (obj.is_default_value === "Y") {
							obj.label = obj.name;
							obj.value = obj.name;
							return obj;
						}
					}
				});
				const formDetails =
					formMenuData.filter(
						(obj) => parseInt(obj["categoryid"]) === componentInfo.filter_id,
					)[0] || {};
				const filter_label = componentInfo.isFormFilters
					? formDetails.label
					: componentInfo.form_label;
				return (
					<SelectRenderer
						options={optionsList}
						isMandatory={false}
						isMulti={
							componentInfo.isFormFilters
								? formDetails.type === "single-select"
									? false
									: true
								: false
						}
						filterLabel={filter_label}
						width={
							componentInfo["isWidthNotRequired"]
								? ""
								: componentInfo["width"]
								? componentInfo["width"]
								: ""
						}
						updateSelected={(params) => {
							handleSelect(
								params,
								componentInfo,
								componentInfo.filter_id,
								optionsList,
							);
						}}
						selectedItems={
							dropdownSelection[`${componentInfo.filter_id}`]
								? dropdownSelection[`${componentInfo.filter_id}`]
								: defaultValue
						}
						{...componentInfo}
						labelType={componentInfo.labelType}
					/>
				);
			case "switch":
				return (
					<>
						<span style={{ fontSize: "12px" }}>{componentInfo.label} </span>{" "}
						<FormControlLabel
							style={componentInfo.style}
							control={
								<Switch
									id="switch"
									onChange={(e) => {
										{
											setcheck(document.getElementById("switch").checked);
										}
										if (componentInfo?.switchAction) {
											if (e.target.checked) {
												invokeAction(componentInfo.switchAction[0])
											} else
												invokeAction(componentInfo.switchAction[1])
										}
										else{
											switchhandler(componentInfo);
										}
									}}
									defaultChecked={componentInfo.defaultCheck}
								/>
							}
							label={
								<Typography sx={{ fontSize: "12px" }}>
									{componentInfo.endLabel}
								</Typography>
							}
						/>
					</>
				);

			case "dropdown-tree-select":
				let dropdownList = fetchHierarchyData(componentInfo.filter_id, {
					data: formMasterValuesData,
					type: "dropdown-tree-select",
				});
				if (
					componentInfo["dependentKey"] &&
					dependentInfo[`${componentInfo.dependentKey}`]
				) {
					dropdownList = fetchHierarchyData(componentInfo.dependentFilterId, {
						data: formMasterValuesData,
						type: "dropdown-tree-select",
					});
				}
				return (
					<DropdownTreeSelectRenderer
						data={dropdownList}
						filterLabel={componentInfo.form_label}
						placeholder={componentInfo.placeholder}
						isMandatory={componentInfo.required}
						{...componentInfo}
						onChange={(currentNode, selectedNodes) => {
							onDropdownTreeChange(
								currentNode,
								selectedNodes,
								componentInfo.action,
							);
						}}
						onAction={onDropdownTreeAction}
						onNodeToggle={onDropdownTreeNodeToggle}
					/>
				);

			case "title":
				return (
					<>
						<h3>
						<span>{componentInfo["redirectToLink"] ? (
							<>
								<Link to={`${baseUrl}${componentInfo.redirectToLink}`}>
									{" "}<ArrowBackIcon />
								</Link>
							</>
						) : null}</span><span style={{paddingTop:"-10px"}}> {componentInfo[`dependentOn`] ? (
								<>
									{dependentInfo[`${componentInfo[`dependentOn`]}`]
										? componentInfo.title
										: null}
								</>
							) : (
								<>{componentInfo.title}</>
							)}</span>
						</h3>
					</>
				);
			case "card":
				return (
					<Typography gutterBottom variant="h5">
						{componentInfo?.title}
						{componentInfo.info?.map((item) => (
							<CustomisedCard item={item} />
						))}
					</Typography>
				);
			case "horizontalCharts":
				return (
					<>
						{componentInfo[`dependentOn`] ? (
							<>
								{dependentInfo[`${componentInfo[`dependentOn`]}`] ? (
									<HorizontalCharts
										charts={componentInfo.info}
										{...componentInfo}
									/>
								) : null}
							</>
						) : (
							<>
								<HorizontalCharts
									charts={componentInfo.info}
									{...componentInfo}
								/>
							</>
						)}
					</>
				);
			case "KPICard":
				return <KpiCardComponent data={componentInfo} />;
			case "split-table-generic":
				return <SplitTableGeneric data={componentInfo.tables} />;
			case "split-table-lululemon":
				return <SplitTableLululemon data={componentInfo.tables} />;
			case "colorScale":
				return <ColorScale data={componentInfo} />;
			case "calendar":
				return <Calendar onCalEventClick={null} dataObj={componentInfo} />;
			case "wrapper":
				return (
					<>
						{componentInfo.components.map((obj, index) =>
							renderComponent(obj, index, true),
						)}
					</>
				);
			case "tabs":
				return (
					<Tabs
						pageObject={{
							tabs: componentInfo.info,
							noReset: componentInfo.noReset,
							components: componentInfo.components,
							redirectionOnClick: componentInfo.redirectionOnClick,
							initialSelectedIndex: componentInfo.initialSelectedIndex,
							...componentInfo,
						}}
					/>
				);
			case "CartersKPICard":
				return <CustomCartersKPICard data={componentInfo} />;
			case "customImage":
				return <CustomImage data={componentInfo} />;
			case "imageDisplay":
				return <ImageDisplay data={componentInfo} />;
			case "textarea":
				return <TextArea data={componentInfo} />;
			case "actionComponents":
				return <ActionComponents data={componentInfo} />;
			case "accordian":
				return <CustomAccordian data={componentInfo} />;
			default:
				break;
		}
	};
	const renderComponent = (obj, index, ignoreSize = false) => {
		let comp = null;
		if (obj["dependentOn"]) {
			let flag = dependentInfo[`${obj.dependentOn}`];
			flag = obj["invertDependence"] ? !flag : flag;
			comp = flag ? renderActionComponents(obj, index) : null;
		} else {
			comp = renderActionComponents(obj, index);
			if ((obj?.size || obj?.r_size) && !comp) {
				comp = (
					<>
						<Grid
							item
							className={
								obj?.size || obj?.r_size
									? "impact-action-components-container-with-size"
									: "impact-action-components-container-without-size"
							}
							sx={obj?.style}
						></Grid>
					</>
				);
			}
		}

		if (comp) {
			// let sizing = obj?.size ? { xs: false }  : { xs: true } ;
			let sizing = { xs: true };
			if (obj?.r_size) {
				sizing = {
					sm: 12,
					...obj.r_size,
				};
			} else if (obj?.size) {
				sizing = obj["dependentOn"]
					? {
							xs: 12,
							md: obj.size,
							lg: obj.size,
							xl: obj.size,
					  }
					: {
							md: obj.size,
							lg: obj.size,
							xl: obj.size,
					  };
			}
			return (
				<Grid
					item
					className={
						obj?.size || obj?.r_size
							? "impact-action-components-container-with-size"
							: "impact-action-components-container-without-size"
					}
					{...(ignoreSize ? "" : sizing)}
					sx={obj?.style}
				>
					{comp}
				</Grid>
			);
		} else return null;
	};
	useEffect(() => {
		return () => {
			if (data?.actionOnUnmount) {
				invokeAction(data.actionOnUnmount)
			}
		}
	},[])
	return (
		<div style={{ border: data.height ? "2px solid #DFE2E8" : "none" }}>
			<Grid
				container
				spacing={1}
				className={"impact-action-components-container"}
				sx={data?.style}
			>
				{componentsList.map((obj, index) => renderComponent(obj, index))}
			</Grid>
			{childComponentsList.length > 0 ? (
				<>
					{/* <div className="content-spacer"></div> */}
					<Grid spacing={1}>
						<ComponentResolver
							pageObject={{ components: childComponentsList }}
						/>
					</Grid>
				</>
			) : null}
		</div>
	);
};

export default ActionComponents;
