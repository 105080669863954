import moment from "moment";

let result = [];
export const currencyFormatter = (params) => {
	return params.value ? "$" + formatNumber(params.value) : null;
};
export const percentageFormatter = (params) => {
	return params.value ? formatNumber(params.value) + "%" : null;
};
export const NoFormatter = (params) => {
	return params.value ? formatNumber(params.value) : null;
};
export const formatNumber = (number) => {
	return Math.floor(number)
		.toString()
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatData = (type, params) => {
	switch (type) {
		case "currencyFormatter":
			return currencyFormatter(params);
		case "percentageFormatter":
			return currencyFormatter(params);
		default:
			if (params.value) {
				if (params.data.symbol) {
					if (params.data.position === "start") {
						return `${params.data.symbol} ${params.value}`;
					}
					if (params.data.position === "end") {
						return `${params.value} ${params.data.symbol} `;
					}
				} else {
					return params.value;
				}
			} else {
				return null;
			}
			break;
	}
};

export const getColumnDefs = (
	columnData,
	isValueFormatterRequired,
	importStyle,
	dispatch
) => {
	columnData.filter((obj) => {
		// value formatter
		if (obj["valueFormatterType"] || isValueFormatterRequired) {
			obj.valueFormatter = function (params) {
				return formatData(obj.valueFormatterType, params);
			};
		}

		// obj.cellStyle = function (params) {
		//     console.log("PARAMS I",params,params.data,params.data && params.data.hasOwnProperty("is_colour"),(params.data && params.data.metric_level  === "OTB Cost $"))
		//     // OTB Cost $
		//    if (params.data && params.data.hasOwnProperty("is_color") && ( params.data.metric_level === "OTB Cost $" )) {
		//     return {backgroundColor: '#f07c7c'};
		//    }

		//   return null;
		// }

		if (!obj["cellStyle"] && !importStyle && !obj["noEditColor"]) {
			obj.cellStyle = function (params) {
				if (params.context && params.context.editedColumns) {
					const data = params.context.editedColumns.filter((obj) => {
						if (
							obj.rowIndex == params.rowIndex &&
							obj.colId == params.column.colId
						) {
							return obj;
						}
					});
					if (data.length > 0) {
						return {
							background: "#f6c6c8",
						};
					}
				}

				// review in season
				let regExp = /[a-zA-Z]/g;
				if (
					params.data &&
					params.data.hasOwnProperty("is_color") &&
					(params.data.metric_level === "OTB Cost $" ||
						params.data.metric_level === "OTB U") &&
					!regExp.test(params.value)
				) {
					return { color: params.value.includes("-") ? "#F46F6F" : "#47CE57" };
				}
			};
		}
		if (obj["children"]) {
			getColumnDefs(
				obj["children"],
				isValueFormatterRequired,
				importStyle,
				dispatch
			);
		}

		// if (obj["children"]) {
		//   obj["children"].filter((nestedObj) => {
		//     if (nestedObj["valueFormatterType"] || isValueFormatterRequired) {
		//       nestedObj.valueFormatter = function (params) {
		//         return formatData(nestedObj.valueFormatterType, params);
		//       };
		//     }
		//     if (!nestedObj["cellStyle"]) {
		//       nestedObj.cellStyle = function (params) {
		//         let regExp = /[a-zA-Z]/g;
		//         if (
		//           params.data &&
		//           params.data.hasOwnProperty("is_color") &&
		//           (params.data.metric_level === "OTB Cost $" ||
		//             params.data.metric_level === "OTB U") &&
		//           !regExp.test(params.value)
		//         ) {
		//           return {
		//             color: params.value.includes("-") ? "#F46F6F" : "#47CE57",
		//           };
		//         }
		//         if (params.context && params.context.editedColumns) {
		//           const nestedData = params.context.editedColumns.filter((obj) => {
		//             console.log(
		//               obj.rowIndex,
		//               params.rowIndex,
		//               obj.colId,
		//               params.column.colId
		//             );
		//             if (
		//               obj.rowIndex == params.rowIndex &&
		//               obj.colId == params.column.colId
		//             ) {
		//               return obj;
		//             }
		//           });
		//           if (nestedData.length > 0) {
		//             return {
		//               background: "#f6c6c8",
		//             };
		//           }
		//         }
		//       };
		//     }
		//   });
		// }
		if (obj["span"]) {
			obj.rowSpan = (params) => {
				return params.data.hasOwnProperty("#span") ? +params.data["#span"] : 1;
			};
			obj.cellStyle = (params) => {
				return {
					backgroundColor: "white",
					height: params.data["#span"] > 1 ? "inherit" : "",
					...obj?.style,
				};
			};
		}
		if (obj["editAction"]) {
			obj.valueSetter = (params) => {
				const isValueChanged = params.newValue !== params.oldValue;
				if (isValueChanged) {
					params.data[obj.field] = params.newValue;
					const dependents = {};
					obj.editAction.forEach((action) => {
						dependents[action.key] = action.value;
					});
					dispatch({
						type: "DEPENDENT_COMPONENTS",
						payload: dependents,
					});
				}
				return isValueChanged;
			};
		}
		if (obj["calculate"]) {
			const funcs = {
				"+": (a, b) => a + b,
				"-": (a, b) => a - b,
				"*": (a, b) => a * b
			};
			const parseArg = (arg, params) => {
				const f = params.colDef?.floatValue
				return typeof arg === "number"
					? arg
					: params.getValue(arg)
						? (f ? parseFloat(params.getValue(arg).replace(/,|%/g, "")) : parseInt(params.getValue(arg).replace(/,|%/g, "")))
					: 0;
				// return (typeof arg === "number") ? arg : (params.getValue(arg) ? parseInt(params.getValue(arg)) : 0 ) ;
			};
			obj.valueGetter = (params) => {
				const seq = params.colDef.calculate;
				let result = parseArg(seq[0], params);
				for (let i = 2, j = 1; i < seq.length; i += 2, j += 2) {
					result = funcs[seq[j]](result, parseArg(seq[i], params));
				}
				return isNaN(result) ? "-" : result.toString() + (params.colDef?.suffix || "");
			};
		}
		if (obj["addWeeksInDate"]) {
			obj.valueGetter = (params) => {
				let dateColumn = obj["addWeeksInDate"][0];
				let weekNoColumn = obj["addWeeksInDate"][1];
				const { data } = params;
				let dateStr = data?.[dateColumn];
				let weekNos = data?.[weekNoColumn];
				let noOfDays = weekNos * 7;
				let new_date = moment(dateStr, "YYYY-MM-DD")
					.add("days", noOfDays)
					.format("YYYY-MM-DD");
				return new_date;
			};
		}
		if (obj["calc"]) {
			const funcs = {
				perc: (a, b) => {
					return Math.round(a * b * 0.01);
				},
				sub: (a, b) => {
					return a - b;
				},
			};
			obj.valueGetter = (params) => {
				const { args, func } = params.colDef.calc;
				const _args = args.map((field) => {
					const value = params.getValue(field);
					return parseInt(value.replace(",", ""));
				});
				const result = funcs[func](..._args);
				return isNaN(result) ? "-" : result.toString();
			};
		}
		if (obj["colorCode"] && obj["rowValue"]) {
			obj.cellStyle = (params) => {
				if (
					params.value &&
					obj["rowValue"].indexOf(params.data[`${obj.rowMappingKey}`]) !== -1
				) {
					// const {
					//   value,
					//   colDef: { colorCode },
					// } = params;
					// let found = null;
					// if (colorCode.type === "range") {
					//   const _value = value.includes('%') ? parseInt(value.replace(/,|%/g, "")) : (isNaN(value) ? value :  parseInt(value) ) ;
					//   found = colorCode.cases.find(
					//     ({ min, max }) => _value >= min && _value < max
					//   );
					// } else {
					//   found = colorCode.cases.find((obj) => obj.value === value);
					// }
					// return {color: 'red', backgroundColor: 'green'};
					// return { background: found ? found.color : colorCode?.default };
					return null;
				}
				return null;
			};
		} else if (obj["colorCode"]) {
			obj.cellStyle = (params) => {
				const {
					value,
					colDef: { colorCode },
				} = params;
				let found = null;
				if (colorCode.type === "range") {
					const _value = parseInt(value.replace(/,|%/g, ""));
					found = colorCode.cases.find(
						({ min, max }) => _value >= min && _value < max
					);
				} else {
					found = colorCode.cases.find((obj) => obj.value === value);
				}
				return { background: found ? found.color : colorCode?.default };
			};
		}
		if (obj["allowCheckboxSuppress"]) {
			obj.checkboxSelection = (params) => {
				return !(params.data["#hideCheckbox"] === "Y");
			};
		}
		return obj;
	});

	return columnData;
};

export const getHeaderNames = (data, callingFrom) => {
	if (!callingFrom) {
		result = [];
	}
	data.filter((obj, index) => {
		if (obj.hasOwnProperty("children")) {
			getHeaderNames(obj["children"], "Nested");
		} else {
			result.push(obj.field);
		}
	});
};

export const defaultCellStyle = (params, props) => {
	if (!props?.importStyle) return null;
	const pinned = params.colDef?.pinned ? true : false;
	const pinnedStyle = {
		background: "#f8f8f8",
	};
	const disabledStyle = {
		background: "#f2f2f2",
		pointerEvents: "none",
		opacity: 0.75,
	};
	if (!params?.data) return (pinned && pinnedStyle.background) || null;
	return {
		background:
			params?.data["#color"] || (pinned && pinnedStyle.background) || "inherit",
		borderTop: params.data["#boundary"] === "Y" ? "2px solid black" : "initial",
		...(params.data["#disable"] === "Y" ||
		params.data[`#disable_${params.colDef.field}`] === "Y"
			? disabledStyle
			: {}),
	};
};
