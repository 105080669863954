import Button from '@mui/material/Button';
const CustomIncremntDecremnt = (props)=>{
    console.log("increment",props.data.label)
    return(
        <div>
        <p style={props.data.labelStyle}>{props.data.label}</p>
        <div>
            <Button style={props.data.decremntStyle}>-1</Button>
            <input style={props.data.inputStyle} type="text" />
            <Button style={props.data.incremntStyle}>+1</Button>
        </div>
        </div>
    )
}

export default CustomIncremntDecremnt
